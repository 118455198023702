import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TitleBar from "../components/Titlebar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../utils/axiosConfig";
import Button from "react-bootstrap/Button";
import UserModal from "../modals/UserModal";
import UserDeleteModal from "../modals/UserDeleteModal";
import UserPasswordModal from "../modals/UserPasswordModal";
import { useAuth } from "../provider/authProvider";

function Users() {
  const { role, setToken } = useAuth();
  const navigate = useNavigate();

  const [showSpinner, setShowSpinner] = useState(false);

  const [users, setUsers] = useState();

  const [user, setUser] = useState();

  const [mode, setMode] = useState("add");

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowdeleteModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const [sUsers, setSearchUsers] = useState(users);
  const [searchVal, setSearchVal] = useState("");

  function handleSearchClick() {
    if (searchVal === "") {
      setSearchUsers(users);
      return;
    }
    const filterBySearch = users.filter((item) => {
      if (item.name.toLowerCase().includes(searchVal.toLowerCase())) {
        return item;
      }
    });
    setSearchUsers(filterBySearch);
  }

  const handleShow = (u) => {
    if (u) {
      setUser(u);
      setMode("edit");
    } else {
      setMode("add");
    }
    setShowModal(true);
  };

  const handleDeleteShow = (u) => {
    if (u) {
      setUser(u);
      setMode("edit");
    } else {
      setMode("add");
    }
    setShowdeleteModal(true);
  };

  const handleChangePasswordShow = (u) => {
    if (u) {
      setUser(u);
      setMode("edit");
    } else {
      setMode("add");
    }
    setShowPasswordModal(true);
  };

  const loadUsers = () => {
    setShowSpinner(true);
    api
      .get("/api/users", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setUsers(response.data);
          setSearchUsers(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchVal(searchVal), 300);
    handleSearchClick();
    return () => clearTimeout(timeOutId);
  }, [searchVal]);

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
  };
  return (
    <>
      <UserModal
        showModal={showModal}
        setShowModal={setShowModal}
        user={mode === "edit" ? user : null}
        mode={mode}
        callBack={loadUsers}
      />
      <UserDeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowdeleteModal}
        user={user}
        callBack={loadUsers}
      />
      <UserPasswordModal
        showModal={showPasswordModal}
        setShowModal={setShowPasswordModal}
        user={mode === "edit" ? user : null}
        mode={mode}
        callBack={loadUsers}
      />
      <TitleBar title='Users'></TitleBar>
      <Row>
        <Col>
          <Form.Control
            pill='true'
            size='sm'
            type='text'
            placeholder='Search Users'
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </Col>
        <Col>
          <Spinner
            animation='border'
            variant='primary'
            className={showSpinner ? "" : "visually-hidden"}
          />
        </Col>
        <Col style={{ display: "flex", justifyContent: "right" }}>
          <Button
            variant='primary me-3'
            className={role === "ADMIN" ? "" : "visually-hidden"}
            onClick={() => handleShow(null)}
          >
            <i className='bi bi-plus-lg' /> Add
          </Button>
        </Col>
      </Row>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sUsers?.map((user, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{capitalize(user.role)}</td>
              <td
                className='text-align-end'
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  variant='dark'
                  onClick={() => handleChangePasswordShow(user)}
                  disabled={user.password === "ME"}
                  className={role === "ADMIN" ? "" : "visually-hidden"}
                >
                  <i className='bi bi-key-fill' />
                </Button>
                &nbsp;
                <Button
                  variant='primary'
                  onClick={() => handleShow(user)}
                  className={role === "ADMIN" ? "" : "visually-hidden"}
                >
                  <i className='bi bi-pencil' />
                </Button>
                &nbsp;
                <Button
                  variant='danger'
                  onClick={() => handleDeleteShow(user)}
                  disabled={user.password === "ME"}
                  className={role === "ADMIN" ? "" : "visually-hidden"}
                >
                  <i className='bi bi-trash' />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default Users;
