import api from "../utils/axiosConfig";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Label from "react-bootstrap/FormLabel";
import Spinner from "react-bootstrap/Spinner";
import { useAuth } from "../provider/authProvider";

function SubCategoryModal({
  showModal,
  setShowModal,
  category,
  mode,
  callBack,
  closeCallback
}) {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showSpinner, setShowSpinner] = useState(false);

  const [nameAr, setNameAr] = useState(category?.nameAr);
  const [nameEn, setNameEn] = useState(category?.nameEn);
  const [code, setCode] = useState(category?.code);
  const [categoryId, setCategory] = useState(category?.nameEn);

  const [categories, setCategories] = useState();

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "nameAr":
        setNameAr(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      case "code":
        setCode(value);
        break;
      case "categoryId":
        setCategory(value);
        break;
      default:
        break;
    }
  }
  const updateCategory = () => {
    setShowSpinner(true);
    api
      .put("/api/scategories/" + category.id, {
        nameAr,
        nameEn,
        code,
        categoryId,
      })
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setShowModal(false);
          clearForm();
          callBack();
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        setErrorMessage(error?.response?.data?.message);
      });
  };

  const addCategory = () => {
    setShowSpinner(true);
    api
      .post("/api/scategories", {
        nameAr,
        nameEn,
        code,
        categoryId,
      })
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 201) {
          setShowModal(false);
          clearForm();
          callBack();
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        setErrorMessage(error?.response?.data?.message);
      });
  };

  function isFormValid() {
    setNameAr(nameAr.trim());
    setNameEn(nameEn.trim());
    return nameAr.trim() !== '' && nameEn.trim() != '' && categoryId > 0;
  }

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    if (isFormValid() === true) {
      setShowSpinner(true);
      category ? updateCategory() : addCategory();
    }

    setValidated(true);
  };

  const handleClose = () => {
    setValidated(false);
    setShowModal(false);
    closeCallback();
  }

  const clearForm = () => {
    setNameAr("");
    setNameEn("");
    setCategory("");
    setCode("");
    setValidated(false);
    setErrorMessage("");
  };

  const loadCategories = () => {
    setShowSpinner(true);
    api
      .get("/api/categories", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setCategories(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    setNameAr(mode === "edit" ? category.nameAr : "");
    setNameEn(mode === "edit" ? category.nameEn : "");
    setCode(mode === "edit" ? category.code : "");
    setCategory(mode === "edit" ? category.categoryId : "");
    setValidated(false);
    setErrorMessage("");
  }, [category]);

  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          onHide={handleClose}
          keyboard={false}
          backdrop='static'
          fullscreen={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{mode === "add" ? "Add" : "Edit"} Cat L1</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated}>
              <Form.Group className='mb-3' controlId='formNameAr'>
                <Form.Label className='fs-6'>Name Arabic</Form.Label>
                <Form.Control
                  type='text'
                  name='nameAr'
                  required
                  placeholder='Enter name'
                  value={nameAr}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid name Arabic.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formNameEn'>
                <Form.Label className='fs-6'>Name English</Form.Label>
                <Form.Control
                  type='text'
                  name='nameEn'
                  required
                  placeholder='Enter name'
                  value={nameEn}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid name English.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formNameCode'>
                <Form.Label className='fs-6'>Code</Form.Label>
                <Form.Control
                  type='number'
                  name='code'
                  placeholder='Enter code'
                  value={code}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid code.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formCategory'>
                <Form.Label className='fs-6'>Cat L0</Form.Label>
                <Form.Select
                  name='categoryId'
                  required
                  value={categoryId}
                  onChange={handleChange}
                >
                  <option value=''></option>
                  {categories &&
                    categories.map((cat) => (
                      <option value={cat.id}>{cat.nameEn.trim()}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid Cat L0.
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Spinner
              animation='border'
              variant='primary'
              className={showSpinner ? "" : "visually-hidden"}
            />
            <Label className='text-danger text-center'>{errorMessage}</Label>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default SubCategoryModal;
