import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TitleBar from "../components/Titlebar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../utils/axiosConfig";
import { useAuth } from "../provider/authProvider";
import { utcDateToLocal } from "../utils/timeFormatter";

function UserLogins() {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const [showSpinner, setShowSpinner] = useState(false);

  const [userLogins, setUserLogins] = useState();

  const [sUserLogins, setSearchUserLogins] = useState(userLogins);
  const [searchVal, setSearchVal] = useState("");

  function handleSearchClick() {
    if (searchVal === "") {
      setSearchUserLogins(userLogins);
      return;
    }
    const filterBySearch = userLogins.filter((item) => {
      if (item.name.toLowerCase().includes(searchVal.toLowerCase())) {
        return item;
      }
    });
    setSearchUserLogins(filterBySearch);
  }

  const loadUserLogins = () => {
    setShowSpinner(true);
    api
      .get("/api/dash/logins?role=MOBILE", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setUserLogins(response.data);
          setSearchUserLogins(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  useEffect(() => {
    loadUserLogins();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchVal(searchVal), 300);
    handleSearchClick();
    return () => clearTimeout(timeOutId);
  }, [searchVal]);

  return (
    <>
      <TitleBar title='User Logins'></TitleBar>
      <Row>
        <Col>
          <Form.Control
            pill='true'
            size='sm'
            type='text'
            placeholder='Search'
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </Col>
        <Col>
          <Spinner
            animation='border'
            variant='primary'
            className={showSpinner ? "" : "visually-hidden"}
          />
        </Col>
      </Row>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Date Time</th>
          </tr>
        </thead>
        <tbody>
          {sUserLogins?.map((locs, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{locs.name}</td>
              <td>{locs.email}</td>
              <td>{utcDateToLocal(locs.lastLogin)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default UserLogins;
