import api from "../utils/axiosConfig";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Label from 'react-bootstrap/FormLabel';
import Spinner from 'react-bootstrap/Spinner';
import { useAuth } from "../provider/authProvider";

function CampModal({showModal, setShowModal, camp, mode, callBack, closeCallback}) {

  const { setToken } = useAuth();
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showSpinner, setShowSpinner] = useState(false);

  const [nameAr, setNameAr] = useState(camp?.nameAr);
  const [nameEn, setNameEn] = useState(camp?.nameEn);
  const [campType, setCampType] = useState(camp?.campType);

  const [zoneId, setZone] = useState();
  const [blockId, setBlock] = useState();

  const [zones, setZones] = useState();
  const [blocks, setBlocks] = useState();

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    switch(name) {
      case 'nameAr': setNameAr(value); break;
      case 'nameEn': setNameEn(value); break;
      case 'campType': setCampType(value); break;
      case 'zoneId': setZone(value); loadBlocksInZone(value); break;
      case 'blockId': setBlock(value); break;
      default: break;
    }
  }
  const updateCamp = () => {
    setShowSpinner(true);
    api.put('/api/camps/' + camp.id, {
      nameAr, nameEn, blockId, campType
    })
    .then(function (response) {
      setShowSpinner(false);
      if(response.status === 200) {
        setShowModal(false);
        clearForm();
        callBack();
      }
    })
    .catch(function (error) {
      setShowSpinner(false);
      setErrorMessage(error?.response?.data?.message);
    });
  }

  const addCamp = () => {
    setShowSpinner(true);
    api.post('/api/camps', {
      nameAr, nameEn, blockId, campType
    })
    .then(function (response) {
      setShowSpinner(false);
      if(response.status === 201) {
        setShowModal(false);
        clearForm();
        callBack();
      }
    })
    .catch(function (error) {
      setShowSpinner(false);
      setErrorMessage(error?.response?.data?.message);
    });
  }

  function isFormValid() {
    setNameAr(nameAr.trim());
    setNameEn(nameEn.trim());
    return nameAr.trim() !== '' && nameEn.trim() != '' && zoneId > 0 && blockId > 0 && campType > 0;
  }

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (isFormValid() === true) {
      setShowSpinner(true);
      camp ? updateCamp() : addCamp();
    }

    setValidated(true);
  };

  const handleClose = () => {
    setValidated(false);
    setShowModal(false);
    closeCallback();
  }

  const clearForm = () => {
    setNameAr("");
    setNameEn("");
    setZone("");
    setBlock("");

    setValidated(false);
    setErrorMessage("");
  };

  const loadZones = () => {
    setShowSpinner(true);
      api.get('/api/zones', {})
      .then(function (response) {
        setShowSpinner(false);
        if(response.status === 200) {
          setZones(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if(error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      }); 
  }

  const loadBlocksInZone = (zoneId) => {
    setShowSpinner(true);
      api.get('/api/blocks/zone/' + zoneId, {})
      .then(function (response) {
        setShowSpinner(false);
        if(response.status === 200) {
          setBlocks(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if(error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      }); 
  }

  useEffect(() => {
    loadZones();
  }, []);

  useEffect(() => {
    setNameAr(mode === "edit" ? camp.nameAr : "");
    setNameEn(mode === "edit" ? camp.nameEn : "");
    setZone(mode === "edit" ? camp.block.zoneId : "");
    setBlock(mode === "edit" ? camp.blockId : "");
    setCampType(mode === "edit" ? camp.campType : "Non Developed");
    if(mode === "edit") loadBlocksInZone(camp.block.zoneId)
    setValidated(false);
    setErrorMessage("");
  }, [camp, mode]);  

    return (
      <>    
      {showModal &&
    <Modal        
        show={showModal}
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{mode === "add" ? "Add" : "Edit"} Camp</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group className="mb-3" controlId="formCamp">
            <Form.Label className="fs-6">Zone</Form.Label>
            <Form.Select name="zoneId" required value={zoneId} onChange={handleChange}>
            <option value=""></option>
              {zones && zones.map(cat => (
                <option value={cat.id}>{cat.nameEn.trim()}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide a valid zone.
            </Form.Control.Feedback>
          </Form.Group>   

          <Form.Group className="mb-3" controlId="formCamp">
            <Form.Label className="fs-6">Block</Form.Label>
            <Form.Select name="blockId" required value={blockId} onChange={handleChange}>
            <option value=""></option>
              {blocks && blocks.map(cat => (
                <option value={cat.id}>{cat.nameEn.trim()}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide a valid block.
            </Form.Control.Feedback>
          </Form.Group>  

        <Form noValidate validated={validated}>
          <Form.Group className="mb-3" controlId="formNameAr">
            <Form.Label className="fs-6">Name Arabic</Form.Label>
            <Form.Control type="text" name="nameAr" required placeholder="Enter name" value={nameAr} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">
              Please provide a valid name Arabic.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formNameEn">
            <Form.Label className="fs-6">Name English</Form.Label>
            <Form.Control type="text" name="nameEn" required placeholder="Enter name" value={nameEn} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">
              Please provide a valid name English.
            </Form.Control.Feedback>
          </Form.Group>
                 
          <Form.Group className="mb-3" controlId="formCamp">
            <Form.Label className="fs-6">Camp Type</Form.Label>
            <Form.Select name="campType" required value={campType} onChange={handleChange}>              
              <option value="Non Developed">Non Developed</option>
              <option value="Developed">Developed</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide a valid zone.
            </Form.Control.Feedback>
          </Form.Group>  

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Spinner animation="border" variant="primary" className={showSpinner ? '' : 'visually-hidden'} />
          <Label className="text-danger text-center">{errorMessage}</Label>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>Save</Button>
        </Modal.Footer>
      </Modal>
      }
      </>
    );
  }
  
  export default CampModal;