import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TitleBar from '../components/Titlebar';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../utils/axiosConfig";
import Button from 'react-bootstrap/Button';
import BlockModal from '../modals/BlockModal';
import { useAuth } from "../provider/authProvider";

function Blocks() {

  const { setToken } = useAuth();
  const navigate = useNavigate();

  const [showSpinner, setShowSpinner] = useState(false);

  const [zoneId, setZoneId] = useState();
  const [zones, setZones] = useState();

  const [blocks, setBlocks] = useState();

  const [block, setBlock] = useState();

  const [mode, setMode] = useState("add");

  const [showModal, setShowModal] = useState(false);

  const [sBlocks, setSearchBlocks] = useState(blocks);
  const [searchVal, setSearchVal] = useState("");
  
  function handleSearchClick() {
    if (searchVal === "") { setSearchBlocks(blocks); return; }
    const filterBySearch = blocks.filter((item) => {
      if (item.nameEn.toLowerCase().includes(searchVal.toLowerCase())) { return item; }
    })
    setSearchBlocks(filterBySearch);
  }

  const handleShow = (u) => {
    if(u) {     
      setBlock(u);       
      setMode("edit");
    } else {      
      setMode("add");
    }
    setShowModal(true);
  };

  const loadBlocks = () => {
    setShowSpinner(true);
      api.get('/api/blocks', {})
      .then(function (response) {
        setShowSpinner(false);
        if(response.status === 200) {
          setBlocks(response.data);
          setSearchBlocks(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if(error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      }); 
  }

  const loadZones = () => {
    setShowSpinner(true);
      api.get('/api/zones', {})
      .then(function (response) {
        setShowSpinner(false);
        if(response.status === 200) {
          setZones(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if(error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      }); 
  }

  const loadInZone = (zoneId) => {
    setShowSpinner(true);
      api.get('/api/blocks/zone/' + zoneId, {})
      .then(function (response) {
        setShowSpinner(false);
        if(response.status === 200) {
          setBlocks(response.data);
          setSearchBlocks(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if(error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      }); 
  }

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    switch(name) {
      case 'zoneId': setZoneId(value); value !== '' ? loadInZone(value) : loadBlocks(); break;
      default: break;
    }
  }

  useEffect(() => {
    loadZones();    
  }, []);

  useEffect(() => {
    loadBlocks();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchVal(searchVal), 300);
    handleSearchClick();
    return () => clearTimeout(timeOutId);
  }, [searchVal]);

  const clearValues = () => {
    setMode("add");
    setBlock(null);
  };
    return (
      <>
      <BlockModal showModal={showModal} setShowModal={setShowModal} block={mode === "edit" ? block : null} mode={mode} callBack={loadBlocks} closeCallback={clearValues} />
      <TitleBar title="Blocks"></TitleBar>
      <Row>
      <Col><Form.Control pill="true" size="sm" type="text" placeholder="Search" onChange={e => setSearchVal(e.target.value)} /></Col>
      <Col>
            <Form.Select name="zoneId" required value={zoneId} onChange={handleChange}>
            <option value="">Select Zone</option>
              {zones && zones.map(cat => (
                <option value={cat.id}>{cat.nameEn.trim()}</option>
              ))}
            </Form.Select>
      </Col>
      <Col><Spinner animation="border" variant="primary" className={showSpinner ? '' : 'visually-hidden'} /></Col>
      <Col style={{display:'flex', justifyContent:'right'}}><Button variant="primary me-3" onClick={() => handleShow(null)}>
        <i className="bi bi-plus-lg" /> Add</Button></Col>  
      </Row>
      <Table responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Name Arabic</th>
          <th>Name English</th>
          <th>Zone</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
            {sBlocks?.map((cats, index) => (
              <tr key={index} >
                <td>{index+1}</td>
                <td>{cats.nameAr}</td>
                <td>{cats.nameEn}</td>
                <td>{cats.zone.nameEn}</td>
                <td className="text-align-end" style={{display:'flex', justifyContent:'right'}}>
                  <Button variant="primary" onClick={() => handleShow(cats)}><i className="bi bi-pencil" /></Button>
                </td>
              </tr>
            ))}

      </tbody>
    </Table>
    
      </>
    );
  }
    
  export default Blocks;