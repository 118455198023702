import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TitleBar from "../components/Titlebar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../utils/axiosConfig";
import Button from "react-bootstrap/Button";
import ItemModal from "../modals/ItemModal";
import { useAuth } from "../provider/authProvider";

function Items() {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const [showSpinner, setShowSpinner] = useState(false);

  const [items, setItems] = useState();

  const [item, setItem] = useState();

  const [categoryId, setCategory] = useState(item?.nameEn);
  const [scategoryId, setSCategory] = useState(item?.nameEn);
  const [subcategoryId, setSubCategory] = useState(item?.nameEn);

  const [scategories, setSCategories] = useState();
  const [subcategories, setSubCategories] = useState();
  const [categories, setCategories] = useState();

  const [mode, setMode] = useState("add");

  const [showModal, setShowModal] = useState(false);

  const [sItems, setSearchItems] = useState(items);
  const [searchVal, setSearchVal] = useState("");

  function handleSearchClick() {
    if (searchVal === "") {
      setSearchItems(items);
      return;
    }
    const filterBySearch = items.filter((item) => {
      if (item.nameEn.toLowerCase().includes(searchVal.toLowerCase())) {
        return item;
      }
    });
    setSearchItems(filterBySearch);
  }

  const handleShow = (u) => {
    if (u) {
      setItem(u);
      setMode("edit");
    } else {
      setMode("add");
    }
    setShowModal(true);
  };

  const loadCategories = () => {
    setShowSpinner(true);
    api
      .get("/api/categories", {})
      .then(function (response) {
        setShowSpinner(false);
        setSubCategories();
        if (response.status === 200) {
          setCategories(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadSubCategories = (catId) => {
    if (catId === "") {
      loadItems();
      return;
    }
    setShowSpinner(true);
    api
      .get("/api/mobile/scategories/" + catId, {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setSCategories(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadSubSubCategories = (catId) => {
    if (catId === "") {
      loadItems();
      return;
    }
    setShowSpinner(true);
    api
      .get("/api/mobile/subcategories/" + catId, {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setSubCategories(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadItemsInSubCategory = (scatId) => {
    setShowSpinner(true);
    api
      .get("/api/mobile/items/" + scatId, {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setItems(response.data);
          setSearchItems(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/login", { replace: true });
        }
      });
  };

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "categoryId":
        setCategory(value);
        setSCategory(0);
        setSubCategory(0);
        loadSubCategories(value);
        break;
      case "scategoryId":
        setSCategory(value);
        setSubCategory(0);
        loadSubSubCategories(value);
        break;
      case "subcategoryId":
        setSubCategory(value);
        value !== "" ? loadItemsInSubCategory(value) : loadItems();
        break;
      default:
        break;
    }
  }

  const loadItems = () => {
    setShowSpinner(true);
    api
      .get("/api/items", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setItems(response.data);
          setSearchItems(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/login", { replace: true });
        }
      });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    loadItems();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchVal(searchVal), 300);
    handleSearchClick();
    return () => clearTimeout(timeOutId);
  }, [searchVal]);

  const clearValues = () => {
    setMode("add");
    setItem(null);
  };

  return (
    <>
      <ItemModal
        showModal={showModal}
        setShowModal={setShowModal}
        item={mode === "edit" ? item : null}
        mode={mode}
        callBack={loadItems}
        closeCallback={clearValues}
      />
      <TitleBar title='Items'></TitleBar>
      <Row>
        <Col>
          <Form.Control
            pill='true'
            size='sm'
            type='text'
            placeholder='Search'
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </Col>
        <Col>
          <Form.Select
            name='categoryId'
            required
            value={categoryId}
            onChange={handleChange}
          >
            <option id='cat_0' value=''>
              Select Cat L0
            </option>
            {categories &&
              categories.map((cat, index) => (
                <option id={"cat_" + index} value={cat.id}>
                  {cat.nameEn.trim()}
                </option>
              ))}
          </Form.Select>
        </Col>
        <Col>
          <Form.Select
            name='scategoryId'
            required
            value={scategoryId}
            onChange={handleChange}
          >
            <option id='scat_0' value=''>
              Select Cat L1
            </option>
            {scategories &&
              scategories.map((cat, index) => (
                <option id={"scat_" + index} value={cat.id}>
                  {cat.nameEn.trim()}
                </option>
              ))}
          </Form.Select>
        </Col>
        <Col>
          <Form.Select
            name='subcategoryId'
            required
            value={subcategoryId}
            onChange={handleChange}
          >
            <option id='scat_0' value=''>
              Select Cat L2
            </option>
            {subcategories &&
              subcategories.map((cat, index) => (
                <option id={"scat_" + index} value={cat.id}>
                  {cat.nameEn.trim()}
                </option>
              ))}
          </Form.Select>
        </Col>
        <Col>
          <Spinner
            animation='border'
            variant='primary'
            className={showSpinner ? "" : "visually-hidden"}
          />
        </Col>
        <Col style={{ display: "flex", justifyContent: "right" }}>
          <Button variant='primary me-3' onClick={() => handleShow(null)}>
            <i className='bi bi-plus-lg' /> Add
          </Button>
        </Col>
      </Row>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Name Arabic</th>
            <th>Name English</th>
            <th>Cat L2</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sItems?.map((cats, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{cats.nameAr}</td>
              <td>{cats.nameEn}</td>
              <td>{cats.subcategory.nameEn}</td>
              <td
                className='text-align-end'
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button variant='primary' onClick={() => handleShow(cats)}>
                  <i className='bi bi-pencil' />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default Items;
