import Spinner from 'react-bootstrap/Spinner';
import TitleBar from '../components/Titlebar';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { useEffect, useState } from "react";
import api from "../utils/axiosConfig";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import {
  CChart,
} from '@coreui/react-chartjs'

function Home() {

  const { role, setToken } = useAuth();

  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);

  const [itemsPerDay, setItemsPerDay] = useState([]);
  const [itemDates, setDates] = useState([]);

  const [catCount, setCatCount] = useState([]);
  const [catNames, setCatNames] = useState([]);

  const [userTagCount, setUserTagCount] = useState([]);
  const [userNames, setUserNames] = useState([]);

  const [showChart, setShowChart] = useState(false);
  const [showCatChart, setShowCatChart] = useState(false);
  const [showTagChart, setShowTagChart] = useState(false);
  const [showTagUChart, setShowTagUChart] = useState(false);

  const itemsExpected = process.env.REACT_APP_TOTAL_ASSETS ?? 50000;
  const [itemsSoFar, setItemsSoFar] = useState(0);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const loadTaggingProgress = () => {
    setShowSpinner(true);
    api.get(`/api/dash/stats/tagging?fromDate=${fromDate}&toDate=${toDate}`, {})
      .then(function (response) {
        setShowSpinner(false);
        if(response.status === 200) {
          var dates = [];
          var counts = [];
          var names = [];
          response.data.forEach(tag => {
            dates.push(tag.itemDate + "");
            counts.push(tag.itemPerDay + "");
            names.push(tag.name + "");
          });
          setItemsPerDay(counts);
          setDates(dates);
          // setUserNames(names);
          setShowChart(true);
          setShowTagChart(true);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if(error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      }); 
  }

  const loadAllTaggingProgress = () => {
    setShowSpinner(true);
    api.get(`/api/dash/stats/tagging?fromDate=&toDate=`, {})
      .then(function (response) {
        setShowSpinner(false);
        if(response.status === 200) {
          var totalItems = 0;
          response.data.forEach(tag => {
            totalItems+=Number(tag.itemPerDay);
          });
          setItemsSoFar(totalItems);          
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if(error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      }); 
  }

  const loadCategoryStats = () => {
    setShowSpinner(true);
      api.get('/api/dash/stats/category', {})
      .then(function (response) {
        setShowSpinner(false);
        if(response.status === 200) {
          var names = [];
          var counts = [];
          response.data.forEach(tag => {
            names.push(tag.catName + "");
            counts.push(tag.itemCount + "");
          });
          setCatCount(counts);
          setCatNames(names);
          setShowCatChart(true);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if(error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      }); 
  }

  const loadUserTaggingProgress = () => {
    setShowSpinner(true);
      api.get('/api/dash/stats', {})
      .then(function (response) {
        setShowSpinner(false);
        if(response.status === 200) {
          var counts = [];
          var names = [];
          response.data.forEach(tag => {
            counts.push(tag.totalItems + "");
            names.push(tag.email + "");
          });
          setUserTagCount(counts);
          setUserNames(names);
          setShowTagUChart(true);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if(error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      }); 
  }

  const getFormattedDate = (date) => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return year + '-' + month + '-' + day;
  }

  useEffect(() => {
    let today = new Date();
    setToDate(getFormattedDate(today));
    today.setDate(today.getDate() - 7);
    setFromDate(getFormattedDate(today));
    loadAllTaggingProgress();
    loadCategoryStats();
    loadUserTaggingProgress();
  }, []);

  useEffect(() => {
    loadTaggingProgress();
  }, [fromDate, toDate]);

  const handleMoreUser = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate("/home/usertagged", { replace: true });
  };

  const handleMoreTagged = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate("/home/userperday", { replace: true });
  };

    return (
      <>
      <TitleBar title="Asset Tagging Project Dashboard"></TitleBar>
      <Spinner animation="border" variant="primary" className={showSpinner ? '' : 'visually-hidden'} />
      <Row>
        <Col className='xSmallFont'>
        Last Updated: {Date()}<br/>&nbsp;
        </Col>
      </Row>
      <Row>
        <Col className='text-center py-2'>        
        {showCatChart &&
            <CChart style={{height:'300px', width:'300px', display: 'ruby'}}
            type="doughnut"
            data={{
              labels: catNames,
              datasets: [
                {
                  backgroundColor: ['#519DE9', '#EF9234', '#38812F', '#009596', '#519DE9', '#F6D173', '#EF9234', '#8481DD'],
                  data: catCount,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: '#000000',
                  }
                }
              },
            }}
          />
          }
        </Col>
        <Col className='text-center'>
          {showChart &&
            <CChart style={{height:'300px', width:'300px', display: 'ruby'}}
            type="doughnut"
            data={{
              labels: ['Tagged', 'Pending'],
              datasets: [
                {
                  backgroundColor: ['#7CC674', '#EF9234', '#00D8FF', '#DD1B16'],
                  data: [itemsSoFar, (itemsExpected-itemsSoFar)],
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: '#000000',
                  }
                }
              },
            }}
          />
          }
        </Col>
      </Row>
      
      <Row>
        <Col className='smallFont text-center'>
          Asset Categories
        </Col>
        <Col className='smallFont text-center'>
          Assets Tagged ({itemsSoFar} out of {itemsExpected} Expected)
        </Col>
      </Row>
      <Row>
        <Col className='py-5' style={{width:'50%', overflowX: 'auto'}}>
        {showTagUChart &&
          <CChart style={{height:'300px'}}
            type="bar"
            data={{
              labels: userNames,
              datasets: [
                {
                  label: 'Assets Tagged By User',
                  backgroundColor: ['#38812F', '#BDE2B9', '#EC7A08', '#009596', '#519DE9', '#F6D173', '#EF9234', '#8481DD'],
                  barPercentage: 0.5,
                  barThickness: 16,
                  maxBarThickness: 18,
                  minBarLength: 2,
                  data: userTagCount,
                },
              ],
            }}
            labels="dates"
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: '#000000',
                  }
                }
              },
              scales: {
                x: {
                  grid: {
                    color: '#000000',
                  },
                  ticks: {
                    color: '#000000',
                  },
                },
                y: {
                  grid: {
                    color: '#000000',
                  },
                  ticks: {
                    color: '#000000',
                  },
                },
              },
            }}
          />
          }
        </Col>
        <Col className='py-5' style={{width:'50%'}}>
        {showTagChart &&
          <CChart style={{height:'300px'}}
            type="bar"
            data={{
              labels: itemDates,
              datasets: [
                {
                  label: 'Assets Tagged Per Day',
                  backgroundColor: ['#EC7A08', '#38812F', '#009596', '#519DE9', '#F6D173', '#EF9234', '#8481DD'],
                  barPercentage: 0.5,
                  barThickness: 16,
                  maxBarThickness: 18,
                  minBarLength: 2,
                  data: itemsPerDay,
                },
              ],
            }}
            labels="dates"
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: '#000000',
                  }
                }
              },
              scales: {
                x: {
                  grid: {
                    color: '#000000',
                  },
                  ticks: {
                    color: '#000000',
                  },
                },
                y: {
                  grid: {
                    color: '#000000',
                  },
                  ticks: {
                    color: '#000000',
                  },
                },
              },
            }}
          />
          }
        </Col>
      </Row>
      
      <Row>
        <Col className='smallFont text-center'>
          Assets Tagged By User <Button variant="link" className={role === 'ADMIN' ? 'noUnderline' : 'noUnderline visually-hidden'} onClick={handleMoreUser}>More &gt;</Button>
        </Col>
        <Col className='smallFont text-center'>
        Assets Tagged Per Day (7 Days) <Button variant="link" className={role === 'ADMIN' ? 'noUnderline' : 'noUnderline visually-hidden'} onClick={handleMoreTagged}>More &gt;</Button>
        </Col>
      </Row>

      </>
    );
  }
  
  export default Home;