import Spinner from 'react-bootstrap/Spinner';
import TitleBar from '../components/Titlebar';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { useEffect, useState, forwardRef } from "react";
import api from "../utils/axiosConfig";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import Table from 'react-bootstrap/Table';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import "react-datepicker/dist/react-datepicker.css";

import {
  CChart,
} from '@coreui/react-chartjs'

function AssetsPerDay() {

  const { role, setToken } = useAuth();

  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);

  const [itemsPerDay, setItemsPerDay] = useState([]);
  const [itemDates, setDates] = useState([]);

  const [totalCount, setTotalCount] = useState(0);

  const [showChart, setShowChart] = useState(false);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const loadTaggingProgress = () => {
    setShowSpinner(true);
      api.get(`/api/dash/stats/tagging?fromDate=${fromDate}&toDate=${toDate}`, {})
      .then(function (response) {
        setShowSpinner(false);
        if(response.status === 200) {
          var dates = [];
          var counts = [];
          var names = [];
          var count = 0;
          response.data.forEach(tag => {
            dates.push(tag.itemDate + "");
            counts.push(tag.itemPerDay + "");
            names.push(tag.name + "");
            count += tag.itemPerDay;
          });
          setItemsPerDay(counts);
          setDates(dates);
          setTotalCount(count);
          setShowChart(true);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if(error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      }); 
  }

  useEffect(() => {    
    setToDate(getFormattedDate(endDate));
    startDate.setDate(endDate.getDate() - 7);
    setFromDate(getFormattedDate(startDate));
    // loadTaggingProgress();
  }, []);

  useEffect(() => {    
    loadTaggingProgress();
  }, [fromDate, toDate]);

  const getFormattedDate = (date) => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return year + '-' + month + '-' + day;
  }

function handleChange(name, value) {

  switch(name) {
    case 'startDate': setStartDate(value); setFromDate(getFormattedDate(value)); break;
    case 'endDate': setEndDate(value); setToDate(getFormattedDate(value));break;

    default: break;
  }
}

const handleSearch = (event) => {
  event.preventDefault();
  event.stopPropagation();
  loadTaggingProgress();
};

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <Button variant="secondary" onClick={onClick} ref={ref}>
    {value}
  </Button>
));

const exportToExcel = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
  saveAs(blob, `${fileName}.xlsx`);
};

const handleExport = () => {
  let allItems = [];
  itemDates.forEach(function (value, i) {
    let data = {
     'RowNum': i + 1,
     'Date': value,
     'Count': itemsPerDay[i]
    }
    allItems.push(data);
  });
  exportToExcel(allItems, 'assets_tagged_per_day_'+Date.now().toString());
};

    return (
      <>
      <TitleBar title="Assets Tagged Per Day"></TitleBar>
      <Spinner animation="border" variant="primary" className={showSpinner ? '' : 'visually-hidden'} />
      <Row style={{alignItems:'center'}}>
        <Col className='xSmallFont'>
        Last Updated: {Date()}<br/>&nbsp;
        </Col>
        <Col sm={2} style={{display:'flex', justifyContent:'right'}}>
          From:&nbsp;<DatePicker customInput={<CustomInput />} maxDate={new Date()} selected={startDate} 
          onChange={(date) => handleChange('startDate', date)} />
        </Col>
        <Col sm={2} style={{display:'flex', justifyContent:'right'}}>
          To:&nbsp;<DatePicker customInput={<CustomInput />} maxDate={new Date()} selected={endDate} 
          onChange={(date) => handleChange('endDate', date)} />
        </Col>
        {/* <Col sm={1}>
          <Button className='visually-hidden' variant="primary" onClick={handleSearch}><i className="bi bi-search" /></Button>
        </Col> */}
        <Col xs={2} style={{display:'flex', justifyContent:'right'}}>
          <Button variant="success" onClick={handleExport} className={role === 'ADMIN' ? '' : 'visually-hidden'}>
          <i class="bi bi-file-earmark-excel-fill"></i> Export</Button>
        </Col>
      </Row>
      <Row style={{display:'flex', justifyContent:'center', fontWeight:'bold'}}>Total Items: {totalCount}</Row>
      <Row>
        <Col className='py-5' style={{width:'90%', overflowX: 'auto'}}>
        {showChart &&
          <CChart style={{width:'200%'}}
            type="bar"
            data={{
              labels: itemDates,
              datasets: [
                {
                  label: 'Assets Tagged Per Day',
                  backgroundColor: ['#EC7A08', '#38812F', '#009596', '#519DE9', '#F6D173', '#EF9234', '#8481DD'],
                  barPercentage: 0.5,
                  barThickness: 16,
                  maxBarThickness: 18,
                  minBarLength: 2,
                  data: itemsPerDay,
                },
              ],
            }}
            labels="dates"
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: '#000000',
                  }
                }
              },
              scales: {
                x: {
                  grid: {
                    color: '#000000',
                  },
                  ticks: {
                    color: '#000000',
                  },
                },
                y: {
                  grid: {
                    color: '#000000',
                  },
                  ticks: {
                    color: '#000000',
                  },
                },
              },
            }}
          />
          }
        </Col>
      </Row>

      <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
                
                {itemDates?.map((item, index) => (
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td>{item}</td>
                      <td>{itemsPerDay[index]}</td>
                    </tr>
                  ))}
                  </tbody>
          </Table>

      </>
    );
  }
  
  export default AssetsPerDay;