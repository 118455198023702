import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import logo from '../logo_round.png';
import api from "../utils/axiosConfig";
import Label from 'react-bootstrap/FormLabel';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

const Login = () => {
  const { setToken, setName, setRole } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = (token, name, role) => {
    setToken(token);
    setName(name);
    setRole(role);
    if(location?.state?.from && location?.state?.from !== "/login" && location?.state?.from !== "/")
      navigate(location?.state?.from, { replace: true });
    else
      navigate("/home", { replace: true });
  };

  // axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showSpinner, setShowSpinner] = useState(false);

  const [ formData, setFormData ] = useState({
    email: '',
    password: ''
  });

  function handleChange(e) {
    const key = e.target.name;
    const value = e.target.value;
    setFormData({...formData, [key]: value})
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      setShowSpinner(true);
      api.post('/api/users/login', {
        email: formData.email,
        password: formData.password
      })
      .then(function (response) {
        setShowSpinner(false);
        if(response.status === 200) {
          handleLogin(response.data.accessToken, response.data.name, response.data.role)
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        setErrorMessage(error?.response?.data?.message);
      });      
    }

    setValidated(true);
  };

  return (
    <>
    <Container fluid={true} className='d-flex justify-content-center align-items-center w-100 vh-100'>
    <Card style={{ width: '36rem' }}>
    <Card.Img variant="top" style={{width: "150px", alignSelf: "center"}} src={logo} />
      <Card.Body>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Card.Title className="text-center">Login</Card.Title>
        <Card.Text>
        
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="fs-6">Email address</Form.Label>
            <Form.Control type="email" name="email" required placeholder="Enter email" onChange={handleChange} />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="fs-6">Password</Form.Label>
            <Form.Control type="password" name="password" required placeholder="Password" onChange={handleChange} />
            <Form.Control.Feedback type="invalid">
              Please provide a password.
            </Form.Control.Feedback>
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Remember Me" />
          </Form.Group> */}
        </Card.Text>
        <Button type="submit" variant="primary" className='float-end'>Login</Button>
        </Form>
        <br/>
        <Row><Spinner animation="border" variant="primary" className={showSpinner ? '' : 'visually-hidden'} /><Label className="text-danger text-center">{errorMessage}</Label></Row>
        </Card.Body>      
    </Card>
    </Container>
    </>
    );
};

export default Login;