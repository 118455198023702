import api from "../utils/axiosConfig";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from "react";
import Label from 'react-bootstrap/FormLabel';
import Spinner from 'react-bootstrap/Spinner';

function UserModal({showModal, setShowModal, user, mode, callBack}) {

  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showSpinner, setShowSpinner] = useState(false);

  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [role, setRole] = useState(user?.role);
  const [password, setPassword] = useState();
  const [cpassword, setConfPassword] = useState();

  function handleChange(e) {
    const key = e.target.name;
    const value = e.target.value;

    switch(key) {
      case 'name': setName(value); break;
      case 'email': setEmail(value); break;
      case 'role': setRole(value); break;
      case 'password': setPassword(value); break;
      case 'cpassword': setConfPassword(value); break;
      default: break;
    }
  }

  const updateUser = () => {
    setShowSpinner(true);
    api.put('/api/users/' + user.id, {
      name, role
    })
    .then(function (response) {
      setShowSpinner(false);
      if(response.status === 200) {
        setShowModal(false);
        clearForm();
        callBack();
      }
    })
    .catch(function (error) {
      setShowSpinner(false);
      setErrorMessage(error?.response?.data?.message);
    });
  }

  const addUser = () => {
    setShowSpinner(true);
    api.post('/api/users', {
      name, email, password, role
    })
    .then(function (response) {
      setShowSpinner(false);
      if(response.status === 201) {
        setShowModal(false);
        clearForm();
        callBack();
      }
    })
    .catch(function (error) {
      setShowSpinner(false);
      setErrorMessage(error?.response?.data?.message);
    });
  }

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      setShowSpinner(true);
      user ? updateUser() : addUser();
    }

    setValidated(true);
  };

  const handleClose = () => {setShowModal(false);};

  const clearForm = () => {
    setName("");
    setEmail("");
    setRole("");
    setPassword("");
    setConfPassword("");
    setValidated(false);
    setErrorMessage("");
  };

  useEffect(() => {
    setName(mode === "edit" ? user.name : "");
    setEmail(mode === "edit" ? user.email : "");
    setRole(mode === "edit" ? user.role : "ADMIN");
    setValidated(false);
  }, [user]);

    return (
      <>    
      {showModal &&
    <Modal        
        show={showModal}
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{mode === "add" ? "Add" : "Edit"} User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form noValidate validated={validated}>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label className="fs-6">Name</Form.Label>
            <Form.Control type="text" name="name" required placeholder="Enter name" value={name} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">
              Please provide a valid name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label className="fs-6">Email</Form.Label>
            <Form.Control type="email" name="email" required placeholder="Enter email" value={email} disabled={mode === "edit"} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formRole">
            <Form.Label className="fs-6">Role</Form.Label>
            <Form.Select name="role" value={role} onChange={handleChange}>
              <option value="ADMIN">Admin</option>
              <option value="WEB">Web</option>
              <option value="MOBILE">Mobile</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide a valid billing currency.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formPassword" className={mode === "edit" ? 'invisible' : 'mb-3 visible'}>
            <Form.Label className="fs-6">Password</Form.Label>
            <Form.Control type="password" name="password" required placeholder="Enter password" value={password} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">
              Please provide a valid password.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formConfirmPassword" className={mode === "edit" ? 'invisible' : 'mb-3 visible'}>
            <Form.Label className="fs-6">Confirm Password</Form.Label>
            <Form.Control type="password" name="cpassword" required placeholder="Confirm password" value={cpassword} disabled={mode === "edit"} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">
              Password doesn't match.
            </Form.Control.Feedback>
          </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Spinner animation="border" variant="primary" className={showSpinner ? '' : 'visually-hidden'} />
          <Label className="text-danger text-center">{errorMessage}</Label>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>Save</Button>
        </Modal.Footer>
      </Modal>
      }
      </>
    );
  }
  
  export default UserModal;