import api from "../utils/axiosConfig";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from "react";
import Label from 'react-bootstrap/FormLabel';
import Spinner from 'react-bootstrap/Spinner';

function LocationDeleteModal({showModal, setShowDeleteModal, location, callBack}) {

  const [errorMessage, setErrorMessage] = useState();
  const [showSpinner, setShowSpinner] = useState(false);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");

  const deleteLocation = () => {
    setShowSpinner(true);
    api.delete('/api/locations/' + location.id)
    .then(function (response) {
      setShowSpinner(false);
      if(response.status === 200) {
        setShowDeleteModal(false);
        callBack(0);
      }
    })
    .catch(function (error) {
      setShowSpinner(false);
      setErrorMessage(error?.response?.data?.message);
    });
  }

  const handleDelete = () => {
    setShowSpinner(true);
    if(location)deleteLocation();
  };

  const handleClose = () => {setShowDeleteModal(false)};

  useEffect(() => {
    setName(location?.camp.nameEn + "->" + location?.category.nameEn + "->" + location?.subcategory.nameEn);
    setAddress(location?.qrCode);
  }, [location]);

    return (
      <>    
      {showModal &&
    <Modal        
        show={showModal}
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          To delete asset in '<b>{name}</b>' with QR Code '<b>{address}</b>'.
        </Modal.Body>
        <Modal.Footer>
        <Spinner animation="border" variant="primary" className={showSpinner ? '' : 'visually-hidden'} />
        <Label className="text-danger text-center">{errorMessage}</Label>
        <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger"  onClick={handleDelete}>Yes</Button>
        </Modal.Footer>
      </Modal>
      }
      </>
    );
  }
  
  export default LocationDeleteModal;