import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Home from "../pages/Home";
import Assets from "../pages/Assets";
import Users from "../pages/Users";
import Categories from "../pages/Category";
import SubCategories from "../pages/SubCategory";
import SubSubCategories from "../pages/SubSubCategory";
import Items from "../pages/Item";
import UserStats from "../pages/UserStats";
import AssetsByUser from "../pages/AssetsByUser";
import AssetsPerDay from "../pages/AssetsPerDay";
import UserLogins from "../pages/UserLogins";

import Disciplines from "../pages/Discipline";
import AssetGroups from "../pages/AssetGroup";
import Zones from "../pages/Zone";
import Blocks from "../pages/Block";
import Camps from "../pages/Camp";

const Routes = () => {
  const { token } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [

  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: '/',
      element: <Login />,
      index: true
    },
    {
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/home",
          element: <Home/>,
        },
        {
          path: "/home/usertagged",
          element: <AssetsByUser/>,
        },
        {
          path: "/home/userperday",
          element: <AssetsPerDay/>,
        },
        {
          path: "/assets",
          element: <Assets/>,
        },
        {
          path: "/users",
          element: <Users/>,
        },
        {
          path: "/logout",
          element: <Logout/>,
        },
        {
          path: "/categories",
          element: <Categories/>,
        },
        {
          path: "/scategories",
          element: <SubCategories/>,
        },
        {
          path: "/subcategories",
          element: <SubSubCategories/>,
        },
        {
          path: "/items",
          element: <Items/>,
        },
        {
          path: "/disciplines",
          element: <Disciplines/>,
        },
        {
          path: "/assetgroups",
          element: <AssetGroups/>,
        },
        {
          path: "/logins",
          element: <UserLogins/>,
        },
        {
          path: "/stats",
          element: <UserStats/>,
        },
        {
          path: "/zones",
          element: <Zones/>,
        },
        {
          path: "/blocks",
          element: <Blocks/>,
        },
        {
          path: "/camps",
          element: <Camps/>,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/login",
      element: <Login/>,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
