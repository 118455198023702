import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../logo_round.png';
import Image from 'react-bootstrap/Image';

function Logo() {
    return (
      <>
        <Navbar>
          <Container>
            <Navbar.Brand href="/home" style={{color: "#ffffff", fontSize: "x-large"}}>
            <Image src={logo} roundedCircle />
            &nbsp;Kidana
            </Navbar.Brand>
          </Container>
        </Navbar>
      </>
    );
  }
  
  export default Logo;