import api from "../utils/axiosConfig";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Label from "react-bootstrap/FormLabel";
import Spinner from "react-bootstrap/Spinner";
import { useAuth } from "../provider/authProvider";

function ItemModal({ showModal, setShowModal, item, mode, callBack, closeCallback }) {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showSpinner, setShowSpinner] = useState(false);

  const [nameAr, setNameAr] = useState(item?.nameAr);
  const [nameEn, setNameEn] = useState(item?.nameEn);
  const [categoryId, setCategory] = useState(item?.nameEn);
  const [scategoryId, setSCategory] = useState(item?.nameEn);
  const [subcategoryId, setSubCategory] = useState(item?.nameEn);

  const [scategories, setSCategories] = useState();
  const [subcategories, setSubCategories] = useState();
  const [categories, setCategories] = useState();

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "nameAr":
        setNameAr(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      case "categoryId":
        setCategory(value);
        loadSubCategories(value);
        break;
      case "scategoryId":
        setSCategory(value);
        loadSubSubCategories(value);
        break;
      case "subcategoryId":
        setSubCategory(value);
        break;
      default:
        break;
    }
  }
  const updateItem = () => {
    setShowSpinner(true);
    api
      .put("/api/items/" + item.id, {
        nameAr,
        nameEn,
        subcategoryId,
      })
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setShowModal(false);
          clearForm();
          callBack();
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        setErrorMessage(error?.response?.data?.message);
      });
  };

  const addItem = () => {
    setShowSpinner(true);
    api
      .post("/api/items", {
        nameAr,
        nameEn,
        subcategoryId,
      })
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 201) {
          setShowModal(false);
          clearForm();
          callBack();
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        setErrorMessage(error?.response?.data?.message);
      });
  };

  function isFormValid() {
    setNameAr(nameAr.trim());
    setNameEn(nameEn.trim());
    return nameAr.trim() !== '' && nameEn.trim() != '' && categoryId > 0 && scategoryId > 0 && subcategoryId > 0;
  }

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    if (isFormValid() === true) {
      setShowSpinner(true);
      item ? updateItem() : addItem();
    }

    setValidated(true);
  };

  const handleClose = () => {
    setValidated(false);
    setShowModal(false);
    closeCallback();
  };

  const clearForm = () => {
    setNameAr("");
    setNameEn("");
    setSubCategory("");
    setCategory("");

    setValidated(false);
    setErrorMessage("");
  };

  const loadCategories = () => {
    setShowSpinner(true);
    api
      .get("/api/categories", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setCategories(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadSubCategories = (catId) => {
    setShowSpinner(true);
    api
      .get("/api/mobile/scategories/" + catId, {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setSCategories(response.data);
          if (mode === "edit") {
            response.data.forEach((sCat) => {
              if (sCat.id === item.subcategory.scategoryId) {
                setSCategory(sCat.id);
              }
            });
          }
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadSubSubCategories = (scatId) => {
    setShowSpinner(true);
    api
      .get("/api/mobile/subcategories/" + scatId, {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setSubCategories(response.data);
          if (mode === "edit") {
            response.data.forEach((sCat) => {
              if (sCat.id === item.subcategoryId) {          
                setCategory(sCat.scategory.categoryId);
                loadSubCategories(sCat.scategory.categoryId);
              }
            });
          }
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    setNameAr(mode === "edit" ? item.nameAr : "");
    setNameEn(mode === "edit" ? item.nameEn : "");
    setSubCategory(mode === "edit" ? item.subcategoryId : "");
    if (mode === "edit" && item.subcategory.scategoryId) {
      loadSubSubCategories(item.subcategory.scategoryId);
    }
    setValidated(false);
    setErrorMessage("");
  }, [item, mode]);

  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          onHide={handleClose}
          keyboard={false}
          backdrop='static'
          fullscreen={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{mode === "add" ? "Add" : "Edit"} Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated}>
              <Form.Group className='mb-3' controlId='formCategory'>
                <Form.Label className='fs-6'>Cat L0</Form.Label>
                <Form.Select
                  name='categoryId'
                  required
                  value={categoryId}
                  onChange={handleChange}
                >
                  <option value=''></option>
                  {categories &&
                    categories.map((cat) => (
                      <option value={cat.id}>{cat.nameEn.trim()}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid province.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formCategory'>
                <Form.Label className='fs-6'>Cat L1</Form.Label>
                <Form.Select
                  name='scategoryId'
                  required
                  value={scategoryId}
                  onChange={handleChange}
                >
                  <option value=''></option>
                  {scategories &&
                    scategories.map((cat) => (
                      <option value={cat.id}>{cat.nameEn.trim()}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid Cat L1.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formCategory'>
                <Form.Label className='fs-6'>Cat L2</Form.Label>
                <Form.Select
                  name='subcategoryId'
                  required
                  value={subcategoryId}
                  onChange={handleChange}
                >
                  <option value=''></option>
                  {subcategories &&
                    subcategories.map((cat) => (
                      <option value={cat.id}>{cat.nameEn.trim()}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid Cat L2.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formNameAr'>
                <Form.Label className='fs-6'>Name Arabic</Form.Label>
                <Form.Control
                  type='text'
                  name='nameAr'
                  required
                  placeholder='Enter name'
                  value={nameAr}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid name Arabic.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formNameEn'>
                <Form.Label className='fs-6'>Name English</Form.Label>
                <Form.Control
                  type='text'
                  name='nameEn'
                  required
                  placeholder='Enter name'
                  value={nameEn}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid name English.
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Spinner
              animation='border'
              variant='primary'
              className={showSpinner ? "" : "visually-hidden"}
            />
            <Label className='text-danger text-center'>{errorMessage}</Label>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default ItemModal;
