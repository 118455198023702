import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import NavBar from "../components/NavBar";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const ProtectedRoute = () => {
  const { token } = useAuth();
  const location = useLocation();

  // Check if the user is authenticated
  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/login" state={{from:location.pathname}} />;
  }

  // If authenticated, render the child routes
  return (
    <>
    <Container fluid={true} className='vh-100'>
      <Row className='vh-100'>
        <Col xs={2} md={2} lg={2} style={{backgroundColor: "#323233"}} className=' px-0'>
        <NavBar>
        </NavBar>
        </Col>
        <Col xs={10} md={10} lg={10}>
          <Outlet/>
        </Col>
      </Row>
    </Container>    
    </>
  );
};
