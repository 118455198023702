import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TitleBar from "../components/Titlebar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../utils/axiosConfig";
import Button from "react-bootstrap/Button";
import CategoryModal from "../modals/CategoryModal";
import { useAuth } from "../provider/authProvider";

function Locations() {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const [showSpinner, setShowSpinner] = useState(false);

  const [categories, setCategories] = useState();

  const [category, setCategory] = useState();

  const [mode, setMode] = useState("add");

  const [showModal, setShowModal] = useState(false);

  const [sCategories, setSearchCategories] = useState(categories);
  const [searchVal, setSearchVal] = useState("");

  function handleSearchClick() {
    if (searchVal === "") {
      setSearchCategories(categories);
      return;
    }
    const filterBySearch = categories.filter((item) => {
      if (item.nameEn.toLowerCase().includes(searchVal.toLowerCase())) {
        return item;
      }
    });
    setSearchCategories(filterBySearch);
  }

  const handleShow = (u) => {
    if (u) {
      setCategory(u);
      setMode("edit");
    } else {
      setMode("add");
    }
    setShowModal(true);
  };

  const loadCategories = () => {
    setShowSpinner(true);
    api
      .get("/api/categories", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setCategories(response.data);
          setSearchCategories(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchVal(searchVal), 300);
    handleSearchClick();
    return () => clearTimeout(timeOutId);
  }, [searchVal]);

  const clearValues = () => {
    setMode("add");
    setCategory(null);
  }

  return (
    <>
      <CategoryModal
        showModal={showModal}
        setShowModal={setShowModal}
        category={mode === "edit" ? category : null}
        mode={mode}
        callBack={loadCategories}
        closeCallback={clearValues}
      />
      <TitleBar title='Cat L0'></TitleBar>
      <Row>
        <Col>
          <Form.Control
            pill='true'
            size='sm'
            type='text'
            placeholder='Search'
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </Col>
        <Col>
          <Spinner
            animation='border'
            variant='primary'
            className={showSpinner ? "" : "visually-hidden"}
          />
        </Col>
        <Col style={{ display: "flex", justifyContent: "right" }}>
          <Button variant='primary me-3' onClick={() => handleShow(null)}>
            <i className='bi bi-plus-lg' /> Add
          </Button>
        </Col>
      </Row>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Name Arabic</th>
            <th>Name English</th>
            <th>Code</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sCategories?.map((locs, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{locs.nameAr}</td>
              <td>{locs.nameEn}</td>
              <td>{locs.code}</td>
              <td
                className='text-align-end'
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button variant='primary' onClick={() => handleShow(locs)}>
                  <i className='bi bi-pencil' />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default Locations;
