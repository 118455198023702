import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import { Link, useLocation } from 'react-router-dom';


function NavItem({ icon, path, text, badge }) {
  const currentPage = useLocation().pathname;

  return (
      <>
        <Container className={currentPage === path || currentPage.includes(path) ? 'link active-link' : 'link' }>
            <Row className='link px-2 py-1'>
              <Col sm={1}><i className={icon}></i></Col>
              <Col sm={8} className='text-align-left'><Link to={path} className='link'>{text}</Link></Col>
              <Col><Badge pill="true" bg="primary">{badge?badge:""}</Badge></Col>
            </Row>
          </Container>
      </>
    );
  }
  
  export default NavItem;