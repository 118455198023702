import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TitleBar from "../components/Titlebar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../utils/axiosConfig";
import Button from "react-bootstrap/Button";
import LocationModal from "../modals/LocationModal";
import LocationDeleteModal from "../modals/LocationDeleteModal";
import { useAuth } from "../provider/authProvider";
import { utcDateToLocal } from "../utils/timeFormatter";

function Assets() {
  const { role, setToken } = useAuth();

  const navigate = useNavigate();

  const [showSpinner, setShowSpinner] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [locations, setLocations] = useState();

  const [location, setLocation] = useState();

  const [mode, setMode] = useState("add");

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [sLocations, setSearchLocations] = useState(locations);
  const [searchVal, setSearchVal] = useState("");

  const [searchId, setSearchId] = useState(0);

  const [campId, setCampId] = useState(0);
  const [camps, setCamps] = useState();

  const [categoryId, setCategory] = useState(0);
  const [categories, setCategories] = useState();

  const [scategoryId, setSCategory] = useState(0);
  const [sCategories, setSCategories] = useState();

  const [subCategoryId, setSubCategory] = useState(0);
  const [subCategories, setSubCategories] = useState();

  const [userId, setUser] = useState(0);
  const [users, setUsers] = useState();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  function handleSearchClick() {
    if (searchVal === "") {
      setSearchLocations(locations);
      return;
    }
    const filterBySearch = locations.filter((item) => {
      if (item.qrCode.toLowerCase().includes(searchVal.toLowerCase())) {
        return item;
      }
    });
    setSearchLocations(filterBySearch);
  }

  const handleShow = (u) => {
    if (u) {
      setLocation(u);
      setMode("edit");
    } else {
      setMode("add");
    }
    setShowModal(true);
  };

  const handleDeleteShow = (u) => {
    if (u) {
      setLocation(u);
      setShowDeleteModal(true);
    }
  };

  const loadLocations = (cId) => {
    setShowSpinner(true);
    api
      .get(
        `/api/locations?limit=${limit}&offset=${offset}&campId=${cId}&categoryId=${categoryId}&sCategoryId=${scategoryId}&subCategoryId=${subCategoryId}&userId=${userId}&searchStr=${searchVal}`,
        {}
      )
      .then(function (response) {
        if (response.status === 200) {
          setLocations(response.data.rows);
          setSearchLocations(response.data.rows);
          setTotalCount(response.data.count);
        }
        setShowSpinner(false);
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadAllLocations = () => {
    loadLocations(0);
  };

  const loadLocation = (id) => {
    setShowSpinner(true);
    api
      .get(
        `/api/locations/id/${id}`,
        {}
      )
      .then(function (response) {
        if (response.status === 200) {
          setLocations(response.data.rows);
          setSearchLocations(response.data.rows);
          setTotalCount(response.data.count);
        }
        setShowSpinner(false);
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  useEffect(() => {
    locations?.forEach((location) => {
      loadLocationImage(location);
    });
  }, [locations]);

  const loadLocationImage = (location) => {
    if (!location) return;
    if (!location.id) return;
    api
      .get(`/api/locations/image/${location.id}`, {})
      .then(function (response) {
        if (response.status === 200 && response.data) {
          var image = new Image();
          image.src = "data:image/png;base64," + response.data;
          image.height = "50";
          const element = document.getElementById(`img_${location.id}`);
          element.innerHTML = "";
          element.appendChild(image);
          location.image = image.src;
        }
        location.image = "";
      })
      .catch(function (error) {
        location.image = "";
      });
  };

  useEffect(() => {
    loadLocations(campId);
  }, [offset]);

  useEffect(() => {
    setOffset(0);
    loadLocations(campId);
  }, [limit]);

  useEffect(() => {
    // setPrev(Number(offset) > 1);
    // setNext(!(Number(offset) + Number(limit) >= Number(totalCount)));
  }, [totalCount]);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchVal(searchVal), 300);
    handleSearchClick();
    return () => clearTimeout(timeOutId);
  }, [searchVal]);

  const handleFirstPage = () => {
    setOffset(0);
  };
  const handleLastPage = () => {
    setOffset(Math.floor(totalCount - limit));
  };

  const handlePrevPage = () => {
    if (offset <= 0) {
      return;
    }
    setOffset(offset - limit);
  };

  const handleNextPage = () => {
    if (Number(offset) + Number(limit) >= Number(totalCount)) {
      return;
    }
    setOffset(Number(offset) + Number(limit));
  };

  const loadCamps = () => {
    api
      .get("/api/camps", {})
      .then(function (response) {
        if (response.status === 200) {
          setCamps(response.data);
        }
      })
      .catch(function (error) {
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  useEffect(() => {
    loadCamps();
  }, []);

  const loadUsers = () => {
    api
      .get("/api/users/mobile", {})
      .then(function (response) {
        if (response.status === 200) {
          setUsers(response.data);
        }
      })
      .catch(function (error) {
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const loadCategories = () => {
    api
      .get("/api/categories", {})
      .then(function (response) {
        if (response.status === 200) {
          setCategories(response.data);
        }
      })
      .catch(function (error) {
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadSubCategories = (catId) => {
    api
      .get("/api/mobile/scategories/" + catId, {})
      .then(function (response) {
        if (response.status === 200) {
          setSCategories(response.data);
        }
      })
      .catch(function (error) {
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadSubSubCategories = (catId) => {
    api
      .get("/api/mobile/subcategories/" + catId, {})
      .then(function (response) {
        if (response.status === 200) {
          setSubCategories(response.data);
        }
      })
      .catch(function (error) {
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "campId":
        setCampId(value);
        break;
      case "categoryId":
        setCategory(value);
        setSCategory(0);
        setSubCategory(0);
        loadSubCategories(value);
        break;
      case "scategoryId":
        setSCategory(value);
        setSubCategory(0);
        loadSubSubCategories(value);
        break;
      case "subCategoryId":
        setSubCategory(value);
        break;
      case "userId":
        setUser(value);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if(campId === 0 && categoryId === 0 && scategoryId === 0 && subCategoryId === 0 && userId === 0 && showFilter) {
      loadAllLocations();
      setShowFilter(!showFilter);
    }

  }, [campId, categoryId, scategoryId, subCategoryId, userId]);
  
  const clearAll = () => {
    setOffset(0);
    setCampId(0);
    setUser(0);
    setCategory(0);
    setSCategory(0);
    setSubCategory(0);
    setSCategories([]);
    setSubCategories([]);    
    const timeOutId = setTimeout(() => setSearchVal(""), 300);   
    
    return () => clearTimeout(timeOutId);
  };

  const clearValues = () => {
    setMode("add");
    setLocation(null);
  };

  return (
    <>
      <LocationModal
        showModal={showModal}
        setShowModal={setShowModal}
        location={mode === "edit" ? location : null}
        mode={mode}
        callBack={loadAllLocations}
        closeCallback={clearValues}
      />
      <LocationDeleteModal
        showModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        location={location}
        callBack={loadAllLocations}
      />
      <TitleBar title='Assets'></TitleBar>
      <Row style={{ alignItems: "center" }}>
        <Col>
          <Form.Control
            pill='true'
            size='sm'
            type='text'
            placeholder='Filter by QR code'
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </Col>

        <Col>
          <Spinner
            animation='border'
            variant='primary'
            className={showSpinner ? "" : "visually-hidden"}
          />
        </Col>

        <Col className="text-align-end">
        <Row>
          <Col>
          <Form.Control
            pill='true'
            size='sm'
            type='number'
            placeholder='Id'
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
          />
          </Col>
          <Col xs={2}>
          <Button
            variant='success'
            onClick={() => {
              loadLocation(searchId);
            }}
          >
            <i class="bi bi-search"></i>
          </Button>
          </Col>
          <Col xs={2}>
          <Button
            variant='dark'
            className={searchId ? 'visible' : 'invisible'}
            onClick={() => {
              setSearchId('');
              loadAllLocations();
            }}
          >
            <i class="bi bi-x"></i>
          </Button>
          </Col>
        </Row>
          
          
        </Col>

        
        <Col xs={2}>
          <Form.Select
            name='limit'
            size='sm'
            value={limit}
            onChange={(e) => setLimit(Number(e.target.value))}
          >
            <option value='10'>10 Items</option>
            <option value='50'>50 Items</option>
            <option value='100'>100 Items</option>
            <option value='500'>500 Items</option>
          </Form.Select>
        </Col>
        <Col
          xs={1}
          className='py-2'
          style={{ display: "flex", justifyContent: "right" }}
        >
          <Button
            variant='secondary'
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          >
            <i className='bi bi-filter' />
          </Button>
        </Col>
      </Row>
      <Row className={showFilter ? "" : "visually-hidden"}>
        <Col xs={2}>
          <Form.Select
            name='campId'
            required
            value={campId}
            onChange={handleChange}
          >
            <option value='0'>Select Camp</option>
            {camps &&
              camps.map((cat) => (
                <option value={cat.id}>{cat.nameEn.trim()}</option>
              ))}
          </Form.Select>
        </Col>
        <Col xs={2}>
          <Form.Select
            name='categoryId'
            required
            value={categoryId}
            onChange={handleChange}
          >
            <option value='0'>Select Cat L0</option>
            {categories &&
              categories.map((cat) => (
                <option value={cat.id}>{cat.nameEn.trim()}</option>
              ))}
          </Form.Select>
        </Col>
        <Col xs={2}>
          <Form.Select
            name='scategoryId'
            required
            value={scategoryId}
            onChange={handleChange}
          >
            <option value='0'>Select Cat L1</option>
            {sCategories &&
              sCategories.map((cat) => (
                <option value={cat.id}>{cat.nameEn.trim()}</option>
              ))}
          </Form.Select>
        </Col>
        <Col xs={3}>
          <Form.Select
            name='subCategoryId'
            required
            value={subCategoryId}
            onChange={handleChange}
          >
            <option value='0'>Select Cat L2</option>
            {subCategories &&
              subCategories.map((cat) => (
                <option value={cat.id}>{cat.nameEn.trim()}</option>
              ))}
          </Form.Select>
        </Col>
        <Col xs={2}>
          <Form.Select
            name='userId'
            required
            value={userId}
            onChange={handleChange}
          >
            <option value='0'>Select User</option>
            {users &&
              users.map((cat) => (
                <option value={cat.id}>{cat.name.trim()}</option>
              ))}
          </Form.Select>
        </Col>
        <Col
          xs={1}
          className='py-2'
          style={{ display: "flex", justifyContent: "right" }}
        >
          <Button
            variant='success'
            onClick={() => {
              setOffset(0);
              loadLocations(campId);
            }}
          >
            <i className='bi bi-search' />
          </Button>
          <Button variant='danger' className='ms-1' onClick={() => clearAll()}>
            <i className='bi bi-x' />
          </Button>
        </Col>
      </Row>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Id</th>
            <th>Camp</th>
            <th>Cat L0</th>
            <th>Cat L1</th>
            <th>Cat L2</th>
            <th>QR Code</th>
            <th>Image</th>
            <th>User</th>
            <th>Time</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Map</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sLocations?.map((locs, index) => (
            <tr key={index}>
              <td>{index + 1 + offset}</td>
              <td>{locs.id}</td>
              <td>{locs.camp?.nameEn}</td>
              <td>{locs.category?.nameEn}</td>
              <td>{locs.scategory?.nameEn}</td>
              <td>{locs.subcategory?.nameEn}</td>
              <td>{locs.qrCode}</td>
              <td id={`img_${locs.id}`}>
                {locs.image ? (
                  <img
                    src={`data:image/png;base64,${locs.image}`}
                    height='50px'
                  />
                ) : (
                  ""
                )}
              </td>
              <td>{locs.user?.name}</td>
              <td>{utcDateToLocal(locs.createdAt)}</td>
              <td>{locs.latitude}</td>
              <td>{locs.longitude}</td>
              <td>
                <a
                  target='_blank'
                  href={`https://www.google.com/maps/search/?api=1&query=${locs.latitude},${locs.longitude}`}
                  height='100px'
                >
                  <i
                    className='bi bi-geo-fill'
                    style={{ fontSize: "xx-large" }}
                  ></i>
                </a>
              </td>
              <td
                className='text-align-end'
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  variant='primary'
                  style={{ height: "40px", width: "40px" }}
                  onClick={() => handleShow(locs)}
                >
                  <i className='bi bi-pencil-fill' />
                </Button>
              </td>
              <td className='text-align-end'>
                <Button
                  variant='danger'
                  className={role == "ADMIN" ? "" : "visually-hidden"}
                  style={{ height: "40px", width: "40px" }}
                  onClick={() => handleDeleteShow(locs)}
                >
                  <i className='bi bi-trash-fill' />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Pagination controls */}
      <Row>
        <Col
          className='py-2'
          style={{ display: "flex", justifyContent: "right" }}
        >
          <Button
            variant='dark'
            disabled={offset + 1 === 1}
            className='mb-3 visible'
            style={{ height: "40px", width: "60px" }}
            onClick={() => handleFirstPage()}
          >
            <i className='bi bi-chevron-double-left' />
          </Button>
          &nbsp;
          <Button
            variant='dark'
            disabled={offset + 1 === 1}
            className='mb-3 visible'
            style={{ height: "40px", width: "60px" }}
            onClick={() => handlePrevPage()}
          >
            <i className='bi bi-chevron-compact-left' />
          </Button>
        </Col>
        <Col
          className='py-2'
          style={{ display: "flex", justifyContent: "center" }}
        >
          Page {Math.ceil(offset / limit + 1)} of{" "}
          {Math.ceil(totalCount / limit)}
        </Col>
        <Col
          className='py-2'
          style={{ display: "flex", justifyContent: "left" }}
        >
          <Button
            variant='dark'
            disabled={offset + limit + 1 >= totalCount}
            className='mb-3 visible'
            style={{ height: "40px", width: "60px" }}
            onClick={() => handleNextPage()}
          >
            <i className='bi bi-chevron-right' />
          </Button>
          &nbsp;
          <Button
            variant='dark'
            disabled={offset + limit + 1 >= totalCount}
            className='mb-3 visible'
            style={{ height: "40px", width: "60px" }}
            onClick={() => handleLastPage()}
          >
            <i className='bi bi-chevron-double-right' />
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default Assets;
