import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TitleBar from '../components/Titlebar';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../utils/axiosConfig";
import Button from 'react-bootstrap/Button';
import DisciplineModal from '../modals/DisciplineModal';
import { useAuth } from "../provider/authProvider";

function Disciplines() {

  const { setToken } = useAuth();
  const navigate = useNavigate();

  const [showSpinner, setShowSpinner] = useState(false);

  const [disciplines, setDisciplines] = useState();

  const [discipline, setDiscipline] = useState();

  const [mode, setMode] = useState("add");

  const [showModal, setShowModal] = useState(false);

  const [sDisciplines, setSearchDisciplines] = useState(disciplines);
  const [searchVal, setSearchVal] = useState("");
  
  function handleSearchClick() {
    if (searchVal === "") { setSearchDisciplines(disciplines); return; }
    const filterBySearch = disciplines.filter((item) => {
      if (item.nameEn.toLowerCase().includes(searchVal.toLowerCase())) { return item; }
    })
    setSearchDisciplines(filterBySearch);
  }

  const handleShow = (u) => {
    if(u) {     
      setDiscipline(u);       
      setMode("edit");
    } else {      
      setMode("add");
    }
    setShowModal(true);
  };

  const loadDisciplines = () => {
    setShowSpinner(true);
      api.get('/api/disciplines', {})
      .then(function (response) {
        setShowSpinner(false);
        if(response.status === 200) {
          setDisciplines(response.data);
          setSearchDisciplines(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if(error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      }); 
  }

  useEffect(() => {
    loadDisciplines();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchVal(searchVal), 300);
    handleSearchClick();
    return () => clearTimeout(timeOutId);
  }, [searchVal]);

  const clearValues = () => {
    setMode("add");
    setDiscipline(null);
  }

    return (
      <>
      <DisciplineModal showModal={showModal} setShowModal={setShowModal} discipline={mode === "edit" ? discipline : null} mode={mode} callBack={loadDisciplines} closeCallback={clearValues} />
      <TitleBar title="Disciplines"></TitleBar>
      <Row>
      <Col><Form.Control pill="true" size="sm" type="text" placeholder="Search" onChange={e => setSearchVal(e.target.value)} /></Col>
      <Col><Spinner animation="border" variant="primary" className={showSpinner ? '' : 'visually-hidden'} /></Col>
      <Col style={{display:'flex', justifyContent:'right'}}><Button variant="primary me-3" onClick={() => handleShow(null)}>
        <i className="bi bi-plus-lg" /> Add</Button></Col>  
      </Row>
      <Table responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Name Arabic</th>
          <th>Name English</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
            {sDisciplines?.map((locs, index) => (
              <tr key={index} >
                <td>{index+1}</td>
                <td>{locs.nameAr}</td>
                <td>{locs.nameEn}</td>
                <td className="text-align-end" style={{display:'flex', justifyContent:'right'}}>
                  <Button variant="primary" onClick={() => handleShow(locs)}><i className="bi bi-pencil" /></Button>
                </td>
              </tr>
            ))}

      </tbody>
    </Table>
    
      </>
    );
  }
    
  export default Disciplines;