import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TitleBar from "../components/Titlebar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../utils/axiosConfig";
import Button from "react-bootstrap/Button";
import SubSubCategoryModal from "../modals/SubSubCategoryModal";
import { useAuth } from "../provider/authProvider";

function SubSubCategory() {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const [showSpinner, setShowSpinner] = useState(false);

  const [categoryId, setCategoryId] = useState();
  const [categories, setCategories] = useState();

  const [scategoryId, setSCategoryId] = useState();
  const [scategories, setSCategories] = useState();

  const [subcategories, setSubCategories] = useState();

  const [category, setCategory] = useState();

  const [mode, setMode] = useState("add");

  const [showModal, setShowModal] = useState(false);

  const [sCategories, setSearchSubCategories] = useState(categories);
  const [searchVal, setSearchVal] = useState("");

  function handleSearchClick() {
    if (searchVal === "") {
      setSearchSubCategories(subcategories);
      return;
    }
    const filterBySearch = subcategories.filter((item) => {
      if (item.nameEn.toLowerCase().includes(searchVal.toLowerCase())) {
        return item;
      }
    });
    setSearchSubCategories(filterBySearch);
  }

  const handleShow = (u) => {
    if (u) {
      setCategory(u);
      setMode("edit");
    } else {
      setMode("add");
    }
    setShowModal(true);
  };

  const loadSubSubCategories = () => {
    setShowSpinner(true);
    api
      .get("/api/subcategories", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setSubCategories(response.data);
          setSearchSubCategories(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadCategories = () => {
    setShowSpinner(true);
    api
      .get("/api/categories", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setCategories(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadInCategory = (catId) => {
    setShowSpinner(true);
    api
      .get("/api/mobile/scategories/" + catId, {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setSCategories(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadInSubCategory = (scatId) => {
    setShowSpinner(true);
    api
      .get("/api/mobile/subcategories/" + scatId, {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setSubCategories(response.data);
          setSearchSubCategories(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "categoryId":
        setCategoryId(value);
        value !== "" ? loadInCategory(value) : setSCategories([]);
        // loadSubSubCategories();
        break;
      case "scategoryId":
        setSCategoryId(value);
        value !== "" ? loadInSubCategory(value) : loadSubSubCategories();
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    loadSubSubCategories();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchVal(searchVal), 300);
    handleSearchClick();
    return () => clearTimeout(timeOutId);
  }, [searchVal]);

  function getCategoryName(catId) {
    let retVal = "";
    sCategories?.forEach(cat => {
      if(cat.id === catId) {
        retVal = cat.nameEn
      }
    });
    return retVal;
  }

  const clearValues = () => {
    setMode("add");
    setCategory(null);
  }

  return (
    <>
      <SubSubCategoryModal
        showModal={showModal}
        setShowModal={setShowModal}
        category={mode === "edit" ? category : null}
        mode={mode}
        callBack={loadSubSubCategories}
        closeCallback={clearValues}
      />
      <TitleBar title='Cat L2'></TitleBar>
      <Row>
        <Col>
          <Form.Control
            pill='true'
            size='sm'
            type='text'
            placeholder='Search'
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </Col>
        <Col>
          <Form.Select
            name='categoryId'
            required
            value={categoryId}
            onChange={handleChange}
          >
            <option value=''>Select Cat L0</option>
            {categories &&
              categories.map((cat) => (
                <option value={cat.id}>{cat.nameEn.trim()}</option>
              ))}
          </Form.Select>
        </Col>
        <Col>
          <Form.Select
            name='scategoryId'
            required
            value={scategoryId}
            onChange={handleChange}
          >
            <option value=''>Select Cat L1</option>
            {scategories &&
              scategories.map((cat) => (
                <option value={cat.id}>{cat.nameEn.trim()}</option>
              ))}
          </Form.Select>
        </Col>
        <Col>
          <Spinner
            animation='border'
            variant='primary'
            className={showSpinner ? "" : "visually-hidden"}
          />
        </Col>
        <Col style={{ display: "flex", justifyContent: "right" }}>
          <Button variant='primary me-3' onClick={() => handleShow(null)}>
            <i className='bi bi-plus-lg' /> Add
          </Button>
        </Col>
      </Row>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Name Arabic</th>
            <th>Name English</th>
            <th>Code</th>
            <th>Cat L0</th>
            <th>Cat L1</th>
            <th>Discipline</th>
            <th>Asset Group</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sCategories?.map((cats, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{cats.nameAr}</td>
              <td>{cats.nameEn}</td>
              <td>{cats.code}</td>
              <td>{getCategoryName(cats.scategory?.categoryId)}</td>
              <td>{cats.scategory?.nameEn}</td>
              <td>{cats.discipline?.nameEn}</td>
              <td>{cats.assetgroup?.nameEn}</td>
              <td
                className='text-align-end'
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button variant='primary' onClick={() => handleShow(cats)}>
                  <i className='bi bi-pencil' />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default SubSubCategory;
