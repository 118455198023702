import api from "../utils/axiosConfig";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Label from "react-bootstrap/FormLabel";
import Spinner from "react-bootstrap/Spinner";
import { useAuth } from "../provider/authProvider";

function SubSubCategoryModal({
  showModal,
  setShowModal,
  category,
  mode,
  callBack,
  closeCallback
}) {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showSpinner, setShowSpinner] = useState(false);

  const [nameAr, setNameAr] = useState(category?.nameAr);
  const [nameEn, setNameEn] = useState(category?.nameEn);
  const [code, setCode] = useState(category?.code);
  const [categoryId, setCategory] = useState(category?.nameEn);
  const [scategoryId, setSCategory] = useState(category?.nameEn);
  const [disciplineId, setDiscipline] = useState(category?.nameEn);
  const [assetgroupId, setAssetGroup] = useState(category?.nameEn);

  const [categories, setCategories] = useState();
  const [scategories, setSCategories] = useState();
  const [disciplines, setDisciplines] = useState();
  const [assetgroups, setAssetGroups] = useState();

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "nameAr":
        setNameAr(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      case "code":
        setCode(value);
        break;
      case "categoryId":
        setCategory(value);
        loadSCategories(value);
        break;
      case "scategoryId":
        setSCategory(value);
        break;
      case "disciplineId":
        setDiscipline(value);
        break;
      case "assetgroupId":
        setAssetGroup(value);
        break;
      default:
        break;
    }
  }
  const updateCategory = () => {
    setShowSpinner(true);
    api
      .put("/api/subcategories/" + category.id, {
        nameAr,
        nameEn,
        code,
        scategoryId,
        disciplineId,
        assetgroupId,
      })
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setShowModal(false);
          clearForm();
          callBack();
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        setErrorMessage(error?.response?.data?.message);
      });
  };

  const addCategory = () => {
    setShowSpinner(true);
    api
      .post("/api/subcategories", {
        nameAr,
        nameEn,
        code,
        scategoryId,
        disciplineId,
        assetgroupId,
      })
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 201) {
          setShowModal(false);
          clearForm();
          callBack();
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        setErrorMessage(error?.response?.data?.message);
      });
  };

  function isFormValid() {
    setNameAr(nameAr.trim());
    setNameEn(nameEn.trim());
    return nameAr.trim() !== '' && nameEn.trim() != '' && categoryId > 0 && scategoryId > 0;
  }

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    if (isFormValid() === true) {
      setShowSpinner(true);
      category ? updateCategory() : addCategory();
    }

    setValidated(true);
  };

  const handleClose = () => {
    setValidated(false);
    setShowModal(false);
    closeCallback();
  }

  const clearForm = () => {
    setNameAr("");
    setNameEn("");
    setCategory("");
    setCode("");
    setValidated(false);
    setErrorMessage("");
  };

  const loadCategories = () => {
    setShowSpinner(true);
    api
      .get("/api/categories", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setCategories(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadSCategories = (catId) => {
    setShowSpinner(true);
    api
      .get("/api/mobile/scategories/" + catId, {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setSCategories(response.data);
          if (mode === "edit") {
            response.data.forEach((sCat) => {
              if (sCat.id === category.scategoryId)
                setCategory(sCat.category.id);
            });
          }
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadDisciplines = () => {
    setShowSpinner(true);
    api
      .get("/api/disciplines", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setDisciplines(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadAssetGroups = () => {
    setShowSpinner(true);
    api
      .get("/api/assetgroups", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setAssetGroups(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    loadDisciplines();
  }, []);

  useEffect(() => {
    loadAssetGroups();
  }, []);

  useEffect(() => {
    setNameAr(mode === "edit" ? category.nameAr : "");
    setNameEn(mode === "edit" ? category.nameEn : "");
    setCode(mode === "edit" ? category.code : "");
    setCategory("");
    setSCategory(mode === "edit" ? category.scategoryId : "");
    if (mode === "edit" && category.scategory.categoryId) {
      loadSCategories(category.scategory.categoryId);
    }
    setDiscipline(mode === "edit" ? category.disciplineId : "");
    setAssetGroup(mode === "edit" ? category.assetgroupId : "");

    setValidated(false);
    setErrorMessage("");
  }, [category]);

  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          onHide={handleClose}
          keyboard={false}
          backdrop='static'
          fullscreen={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{mode === "add" ? "Add" : "Edit"} Cat L2</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated}>
              <Form.Group className='mb-3' controlId='formNameAr'>
                <Form.Label className='fs-6'>Name Arabic</Form.Label>
                <Form.Control
                  type='text'
                  name='nameAr'
                  required
                  placeholder='Enter name'
                  value={nameAr}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid name Arabic.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formNameEn'>
                <Form.Label className='fs-6'>Name English</Form.Label>
                <Form.Control
                  type='text'
                  name='nameEn'
                  required
                  placeholder='Enter name'
                  value={nameEn}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid name English.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formNameCode'>
                <Form.Label className='fs-6'>Code</Form.Label>
                <Form.Control
                  type='number'
                  name='code'
                  placeholder='Enter code'
                  value={code}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid code.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3' controlId='formCategory'>
                <Form.Label className='fs-6'>Cat L0</Form.Label>
                <Form.Select
                  name='categoryId'
                  required
                  value={categoryId}
                  onChange={handleChange}
                >
                  <option value=''></option>
                  {categories &&
                    categories.map((cat) => (
                      <option value={cat.id}>{cat.nameEn.trim()}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid Cat L0
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formCategory'>
                <Form.Label className='fs-6'>Cat L1</Form.Label>
                <Form.Select
                  name='scategoryId'
                  required
                  value={scategoryId}
                  onChange={handleChange}
                >
                  <option value=''></option>
                  {scategories &&
                    scategories.map((cat) => (
                      <option value={cat.id}>{cat.nameEn.trim()}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid Cat L1.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formDiscipline'>
                <Form.Label className='fs-6'>Discipline</Form.Label>
                <Form.Select
                  name='disciplineId'
                  required
                  value={disciplineId}
                  onChange={handleChange}
                >
                  <option value=''></option>
                  {disciplines &&
                    disciplines.map((cat) => (
                      <option value={cat.id}>{cat.nameEn.trim()}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid discipline.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formAssetGroup'>
                <Form.Label className='fs-6'>Asset Group</Form.Label>
                <Form.Select
                  name='assetgroupId'
                  required
                  value={assetgroupId}
                  onChange={handleChange}
                >
                  <option value=''></option>
                  {assetgroups &&
                    assetgroups.map((cat) => (
                      <option value={cat.id}>{cat.nameEn.trim()}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>
                  Please provide a valid Asset Group.
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Spinner
              animation='border'
              variant='primary'
              className={showSpinner ? "" : "visually-hidden"}
            />
            <Label className='text-danger text-center'>{errorMessage}</Label>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default SubSubCategoryModal;
