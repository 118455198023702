import api from "../utils/axiosConfig";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from "react";
import Label from 'react-bootstrap/FormLabel';
import Spinner from 'react-bootstrap/Spinner';

function DisciplineModal({showModal, setShowModal, discipline, mode, callBack, closeCallback}) {

  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showSpinner, setShowSpinner] = useState(false);

  const [nameAr, setNameAr] = useState(discipline?.nameAr);
  const [nameEn, setNameEn] = useState(discipline?.nameEn);

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    switch(name) {
      case 'nameAr': setNameAr(value); break;
      case 'nameEn': setNameEn(value); break;
      default: break;
    }
  }

   const updateDiscipline = () => {
    setShowSpinner(true);
    api.put('/api/disciplines/' + discipline.id, {
      nameAr, nameEn
    })
    .then(function (response) {
      setShowSpinner(false);
      if(response.status === 200) {
        setShowModal(false);
        clearForm();
        callBack();
      }
    })
    .catch(function (error) {
      setShowSpinner(false);
      setErrorMessage(error?.response?.data?.message);
    });
  }

  const addDiscipline = () => {
    setShowSpinner(true);
    api.post('/api/disciplines', {
      nameAr, nameEn
    })
    .then(function (response) {
      setShowSpinner(false);
      if(response.status === 201) {
        setShowModal(false);
        clearForm();
        callBack();
      }
    })
    .catch(function (error) {
      setShowSpinner(false);
      setErrorMessage(error?.response?.data?.message);
    });
  }

  function isFormValid() {
    setNameAr(nameAr.trim());
    setNameEn(nameEn.trim());
    return nameAr.trim() !== '' && nameEn.trim() != '';
  } 

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (isFormValid() === true) {
      setShowSpinner(true);
      discipline ? updateDiscipline() : addDiscipline();
    }

    setValidated(true);
  };

  const handleClose = () => {
    setValidated(false);
    setShowModal(false);
    closeCallback();
  }

  const clearForm = () => {
    setNameAr("");
    setNameEn("");

    setValidated(false);
    setErrorMessage("");
  };

  useEffect(() => {
    setNameAr(mode === "edit" ? discipline.nameAr : "");
    setNameEn(mode === "edit" ? discipline.nameEn : "");
    setValidated(false);
    setErrorMessage("");
  }, [discipline, mode]);  

    return (
      <>    
      {showModal &&
    <Modal        
        show={showModal}
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{mode === "add" ? "Add" : "Edit"} Discipline</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form noValidate validated={validated}>
          <Form.Group className="mb-3" controlId="formNameAr">
            <Form.Label className="fs-6">Name Arabic</Form.Label>
            <Form.Control type="text" name="nameAr" required placeholder="Enter name" value={nameAr} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">
              Please provide a valid name Arabic.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formNameEn">
            <Form.Label className="fs-6">Name English</Form.Label>
            <Form.Control type="text" name="nameEn" required placeholder="Enter name" value={nameEn} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">
              Please provide a valid name English.
            </Form.Control.Feedback>
          </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Spinner animation="border" variant="primary" className={showSpinner ? '' : 'visually-hidden'} />
          <Label className="text-danger text-center">{errorMessage}</Label>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>Save</Button>
        </Modal.Footer>
      </Modal>
      }
      </>
    );
  }
  
  export default DisciplineModal;