import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import TitleBar from "../components/Titlebar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { useEffect, useState } from "react";
import api from "../utils/axiosConfig";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { utcDateToLocal } from "../utils/timeFormatter";

function UserStats() {
  const { role, setToken } = useAuth();
  const [userStats, setUserStats] = useState();

  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);

  const loadUserStats = () => {
    setShowSpinner(true);
    api
      .get("/api/dash/stats", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setUserStats(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  useEffect(() => {
    loadUserStats();
  }, []);

  return (
    <>
      <TitleBar title='User Data Collection Stats'></TitleBar>
      <Spinner
        animation='border'
        variant='primary'
        className={showSpinner ? "" : "visually-hidden"}
      />
      <Row style={{ display: "flex", justifyContent: "left" }}>
        {userStats?.map((user, index) => (
          <Col
            xs={6}
            sm={4}
            md={3}
            style={{ display: "flex", padding: "10px" }}
          >
            <Card style={{ width: "24rem" }}>
              <Card.Header className='customCard'>
                <b>{user.name}</b>
              </Card.Header>
              <Card.Body>
                <Card.Title>{user.email}</Card.Title>
                <Card.Text className='smallerFont'>
                  Last Login: <b>{utcDateToLocal(user.lastLogin)}</b>
                  <br />
                  <b>Today: </b>
                  {user.itemsToday}
                  <br />
                  <b>Total: </b> {user.totalItems}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default UserStats;
