import api from "../utils/axiosConfig";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from "react";
import Label from 'react-bootstrap/FormLabel';
import Spinner from 'react-bootstrap/Spinner';

function UserDeleteModal({showModal, setShowModal, user, callBack}) {

  const [errorMessage, setErrorMessage] = useState();
  const [showSpinner, setShowSpinner] = useState(false);

  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [role, setRole] = useState(user?.role);

  const deleteUser = () => {
    setShowSpinner(true);
    api.delete('/api/users/' + user.id)
    .then(function (response) {
      setShowSpinner(false);
      if(response.status === 200) {
        setShowModal(false);
        callBack();
      }
    })
    .catch(function (error) {
      setShowSpinner(false);
      setErrorMessage(error?.response?.data?.message);
    });
  }

  const handleDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      setShowSpinner(true);
      if(user)deleteUser();
    }

  };

  const handleClose = () => {setShowModal(false)};

  useEffect(() => {
    setName(user?.name);
    setEmail(user?.email);
    setRole(user?.role);
  }, [user]);

    return (
      <>    
      {showModal &&
    <Modal        
        show={showModal}
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          To delete '<b>{name}</b>' with email address '<b>{email}</b>' and role '<b>{role}</b>'.
        </Modal.Body>
        <Modal.Footer>
        <Spinner animation="border" variant="primary" className={showSpinner ? '' : 'visually-hidden'} />
        <Label className="text-danger text-center">{errorMessage}</Label>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary"  onClick={handleDelete}>Yes</Button>
        </Modal.Footer>
      </Modal>
      }
      </>
    );
  }
  
  export default UserDeleteModal;