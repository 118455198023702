import api from "../utils/axiosConfig";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Label from "react-bootstrap/FormLabel";
import Spinner from "react-bootstrap/Spinner";
import { useAuth } from "../provider/authProvider";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { utcDateToLocal } from "../utils/timeFormatter";

function LocationModal({
  showModal,
  setShowModal,
  location,
  mode,
  callBack, closeCallback
}) {
  const { role, setToken } = useAuth();
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showSpinner, setShowSpinner] = useState(false);

  const [qrCode, setQrcode] = useState(location?.qrCode);

  const [campId, setCampId] = useState(0);
  const [camps, setCamps] = useState();

  const [categoryId, setCategory] = useState(0);
  const [categories, setCategories] = useState();

  const [scategoryId, setSCategory] = useState(0);
  const [sCategories, setSCategories] = useState();

  const [subCategoryId, setSubCategory] = useState(0);
  const [subCategories, setSubCategories] = useState();

  const [userId, setUser] = useState(0);
  const [users, setUsers] = useState();

  const [image, setImage] = useState();

  const [comment, setComment] = useState();

  const handleClose = () => {
    setValidated(false);
    setShowModal(false);
    closeCallback();
  };

  const updateAsset = () => {
    setShowSpinner(true);
    api
      .put("/api/mobile/asset/" + location.id, {
        id: location.id,
        campId,
        categoryId,
        scategoryId,
        subCategoryId,
        userId,
        qrCode,
        image,
        comments: comment,
        items,
      })
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setShowModal(false);
          callBack();
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        setErrorMessage(error?.response?.data?.message);
      });
  };

  function isFormValid() {
    return campId > 0 && userId > 0 && categoryId > 0 && scategoryId > 0 && subCategoryId > 0 && comment && comment.trim() !== "";
  }

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    if (isFormValid() === true) {
      updateAsset();
    }
    setValidated(true);
  };

  const [items, setItems] = useState();

  const [allItems, setAllItems] = useState();

  useEffect(() => {
    setQrcode(mode === "edit" ? location.qrCode : "");
    setCampId(mode === "edit" ? location.campId : "");
    setCategory(mode === "edit" ? location.categoryId : 0);
    if (mode === "edit" && location.categoryId) {
      loadSCategories(location.categoryId);
    }
    setSCategory(mode === "edit" ? location.sCategoryId : 0);
    if (mode === "edit" && location.sCategoryId) {
      loadSubCategories(location.sCategoryId);
    }
    setSubCategory(mode === "edit" ? location.subcategoryId : 0);
    if (mode === "edit" && location.subcategoryId) {
      loadItemsInSubCategory(location.subcategoryId);
    }
    setUser(mode === "edit" ? location.userId : "");
    setComment(mode === "edit" ? location.comments : "");
    setErrorMessage("");
    setValidated(false);
    if (location) {
      loadItems(location.id);
      loadLocationImage(location);
    }
  }, [location, mode]);

  const loadItems = (locationId) => {
    setShowSpinner(true);
    api
      .get("/api/locations/items/" + locationId, {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setItems(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  // useEffect(() => {
  //   loadItems(location?.id);
  //   loadLocationImage(location);
  // }, [location]);

  const loadLocationImage = (location) => {
    if (!location) return;
    if (!location.id) return;
    api
      .get(`/api/locations/image/${location.id}`, {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200 && response.data) {
          setImage(response.data);
        }
        location.image = "";
      })
      .catch(function (error) {
        location.image = "";
      });
  };

  const loadCamps = () => {
    setShowSpinner(true);
    api
      .get("/api/camps", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setCamps(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  useEffect(() => {
    loadCamps();
    loadCategories();
    loadUsers();
  }, []);

  const loadCategories = () => {
    setShowSpinner(true);
    api
      .get("/api/categories", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setCategories(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadSCategories = (catId) => {
    setShowSpinner(true);
    api
      .get("/api/mobile/scategories/" + catId, {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setSCategories(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadSubCategories = (catId) => {
    setShowSpinner(true);
    api
      .get("/api/mobile/subcategories/" + catId, {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setSubCategories(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadUsers = () => {
    setShowSpinner(true);
    api
      .get("/api/users/mobile", {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setUsers(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/", { replace: true });
        }
      });
  };

  const loadItemsInSubCategory = (scatId) => {
    setShowSpinner(true);
    api
      .get("/api/mobile/items/" + scatId, {})
      .then(function (response) {
        setShowSpinner(false);
        if (response.status === 200) {
          setAllItems(response.data);
        }
      })
      .catch(function (error) {
        setShowSpinner(false);
        //setErrorMessage(error.response.data.message);
        if (error.response.status === 401) {
          setToken();
          navigate("/login", { replace: true });
        }
      });
  };

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "campId":
        setCampId(value);
        break;
      case "categoryId":
        setCategory(value);
        setSCategory(0);
        setSubCategory(0);
        loadSCategories(value);
        break;
      case "scategoryId":
        setSCategory(value);
        setSubCategory(0);
        loadSubCategories(value);
        break;
      case "subCategoryId":
        setSubCategory(value);
        loadItemsInSubCategory(value);
        break;
      case "userId":
        setUser(value);
        break;
      case "image":
        setImage(image);
        break;
      case "qrCode":
        setQrcode(value);
        break;
      case "comment":
        setComment(value);
        break;
      default:
        break;
    }
    setErrorMessage("");
  }

  function handleItemChange(e, index) {
    const name = e.target.name;
    const value = e.target.value;

    const nextCounters = items.map((c, i) => {
      if (i === index) {
        if (name.startsWith("item")) {
          c.itemId = value;
        } else if (name.startsWith("spec")) {
          c.spec = value;
        } else if (name.startsWith("qty")) {
          c.quantity = value;
        }
      }
      return c;
    });
    setItems(nextCounters);
  }

  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          onHide={handleClose}
          keyboard={false}
          backdrop='static'
          fullscreen={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Asset Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated}>
              <Row>
                <Col>
                  <Form.Group className='mb-3' controlId='formName'>
                    <Form.Label className='fs-6'>Camp</Form.Label>
                    <Form.Select
                      name='campId'
                      required
                      disabled={role !== "ADMIN"}
                      value={campId}
                      onChange={handleChange}
                    >
                      <option value=''>Select Camp</option>
                      {camps &&
                        camps.map((cat) => (
                          <option value={cat.id}>{cat.nameEn.trim()}</option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3' controlId='formName'>
                    <Form.Label className='fs-6'>User</Form.Label>
                    <Form.Select
                      name='userId'
                      required
                      disabled={role !== "ADMIN"}
                      value={userId}
                      onChange={handleChange}
                    >
                      <option value=''>Select User</option>
                      {users &&
                        users.map((cat) => (
                          <option value={cat.id}>{cat.name.trim()}</option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3' controlId='formName'>
                    <Form.Label className='fs-6'>Cat L0</Form.Label>
                    <Form.Select
                      name='categoryId'
                      required
                      disabled={role !== "ADMIN"}
                      value={categoryId}
                      onChange={handleChange}
                    >
                      <option value=''>Select Cat L0</option>
                      {categories &&
                        categories.map((cat) => (
                          <option value={cat.id}>{cat.nameEn.trim()}</option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      Please select a Cat L0.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3' controlId='formName'>
                    <Form.Label className='fs-6'>Cat L1</Form.Label>
                    <Form.Select
                      name='scategoryId'
                      required
                      disabled={role !== "ADMIN"}
                      value={scategoryId}
                      onChange={handleChange}
                    >
                      <option value=''>Select Cat L1</option>
                      {sCategories &&
                        sCategories.map((cat) => (
                          <option value={cat.id}>{cat.nameEn.trim()}</option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      Please select a Cat L1.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className='mb-3' controlId='formName'>
                    <Form.Label className='fs-6'>Cat L2</Form.Label>
                    <Form.Select
                      name='subCategoryId'
                      required
                      disabled={role !== "ADMIN"}
                      value={subCategoryId}
                      onChange={handleChange}
                    >
                      <option value=''>Select Cat L2</option>
                      {subCategories &&
                        subCategories.map((cat) => (
                          <option value={cat.id}>{cat.nameEn.trim()}</option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>
                      Please select a Cat L2.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3' controlId='formName'>
                    <Form.Label className='fs-6'>QR Code</Form.Label>
                    <Form.Control
                      type='text'
                      name='qrCode'
                      disabled={role !== "ADMIN"}
                      placeholder='QR Code'
                      value={qrCode}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className='mb-3' controlId='formCreated'>
                    <Form.Label className='fs-6'>Created Date Time</Form.Label>
                    <Form.Control
                      type='text'
                      readOnly
                      name='createdAt'
                      required
                      placeholder='Date Time'
                      value={utcDateToLocal(location.createdAt)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3' controlId='formUpdated'>
                    <Form.Label className='fs-6'>Updated Date Time</Form.Label>
                    <Form.Control
                      type='text'
                      readOnly
                      name='updatedAt'
                      required
                      placeholder='Date Time'
                      value={utcDateToLocal(location.updatedAt)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col style={{ textAlign: "center" }}>
                  <p id={`imgL_${location.id}`}>
                    {image ? (
                      <img
                        src={"data:image/png;base64," + image}
                        height={200}
                      />
                    ) : (
                      ""
                    )}
                  </p>
                  <Button
                    variant='danger'
                    className={image !== "" ? "" : "visually-hidden"}
                    disabled={role !== "ADMIN"}
                    onClick={() => {
                      setImage("");
                    }}
                  >
                    <i className='bi bi-trash3-fill'></i>
                  </Button>
                </Col>
                <Col>
                  <Form.Group className='mb-3' controlId='formComment'>
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      as='textarea'
                      name='comment'
                      required
                      rows={8}
                      value={comment}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please explain why editing this.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Item</th>
                    <th>Spec</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {items?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Form.Select
                          name={`itemId_${index}`}
                          disabled={role !== "ADMIN"}
                          required
                          value={item.itemId}
                          onChange={(e) => handleItemChange(e, index)}
                        >
                          <option value=''>Select Item</option>
                          {allItems &&
                            allItems.map((cat) => (
                              <option value={cat.id}>
                                {cat.nameEn.trim()}
                              </option>
                            ))}
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Control
                          type='text'
                          disabled={role !== "ADMIN"}
                          name={`spec_${index}`}
                          placeholder='Spec'
                          value={item.spec}
                          onChange={(e) => handleItemChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type='number'
                          disabled={role !== "ADMIN"}
                          name={`qty${index}`}
                          placeholder='Qty'
                          value={item.quantity}
                          onChange={(e) => handleItemChange(e, index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Spinner
              animation='border'
              variant='primary'
              className={showSpinner ? "" : "visually-hidden"}
            />
            <Label className='text-danger text-center'>{errorMessage}</Label>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
            <Button
              variant='primary'
              className={role === "ADMIN" ? "" : "visually-hidden"}
              onClick={handleSave}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default LocationModal;
