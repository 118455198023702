import Stack from "react-bootstrap/Stack";
import Logo from "./Logo";
import NavItem from "./NavItem";
import { useAuth } from "../provider/authProvider";

function NavBar() {
  const { role } = useAuth();
  return (
    <>
      <Stack gap={0}>
        <Logo />
        <NavItem
          icon={"bi bi-house-door-fill"}
          path={"/home"}
          text='Dashboard'
        />
        <span className='link-text'>Location</span>
        <NavItem icon={"bi bi-geo-alt-fill"} path={"/zones"} text='Zones' />
        <NavItem
          icon={"bi bi-blockquote-right"}
          path={"/blocks"}
          text='Blocks'
        />
        <NavItem icon={"bi bi-crosshair2"} path={"/camps"} text='Camps' />
        <span className='link-text'>Master</span>
        <NavItem icon={"bi bi-people"} path={"/users"} text='Users' />
        <NavItem
          icon={"bi bi-collection"}
          path={"/disciplines"}
          text='Disciplines'
        />
        <NavItem
          icon={"bi bi-collection-fill"}
          path={"/assetgroups"}
          text='Asset Groups'
        />
        <NavItem icon={"bi bi-tag-fill"} path={"/categories"} text='Cat L0' />
        <NavItem icon={"bi bi-tags-fill"} path={"/scategories"} text='Cat L1' />
        <NavItem
          icon={"bi bi-tags-fill"}
          path={"/subcategories"}
          text='Cat L2'
        />
        <NavItem icon={"bi bi-diagram-2"} path={"/items"} text='Items' />
        {role === "ADMIN" && <span className='link-text'>Reports</span>}
        {role === "ADMIN" && (
          <NavItem
            icon={"bi bi-people-fill"}
            path={"/logins"}
            text='User Logins'
          />
        )}
        {role === "ADMIN" && (
          <NavItem icon={"bi bi-activity"} path={"/stats"} text='User Stats' />
        )}

        <hr />
        <span className='link-text'>Data</span>
        <NavItem
          icon={"bi bi-blockquote-right"}
          path={"/assets"}
          text='Assets'
        />
      </Stack>
    </>
  );
}

export default NavBar;
