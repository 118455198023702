import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useAuth } from "../provider/authProvider";

function NavBar({title}) {
  const { name } = useAuth();
  const navDropdownTitle = (<i className="bi bi-person-fill large-icon"></i>);
    return (
      <>
        <Row  className="align-middle">
        <Col><h2 className='px-3 py-3'>{title}</h2></Col>
        <Col xs={2} className="py-2" style={{display:'flex', justifyContent:'right'}}>Hi, {name}&nbsp;
        
        </Col>
        <Col xs={1} className="py-2" style={{display:'flex', justifyContent:'right'}}>
          <NavDropdown title={navDropdownTitle} id="basic-nav-dropdown" drop="left">
              <NavDropdown.Item href="/logout"><i className="bi bi-box-arrow-right large-icon" /> Log Out</NavDropdown.Item>
          </NavDropdown>
        </Col>
      </Row>
      </>
    );
  }
  
  export default NavBar;